import { TARGET_MAIN_OPTION } from 'new/constants/general';
import { TargetType } from 'new/modules/ManagePlans/types';
import create from 'zustand';

type LocationSelectorStore = {
  enabled: boolean;
  setEnabled: (enabled: boolean) => void;
  selectedLocation: string;
  setSelectedLocation: (selectedLocation: string) => void;
  selectedLocationName: string;
  setSelectedLocationName: (selectedLocationName: string) => void;
  targetType: TargetType;
  setTargetType: (targetType: TargetType) => void;
};
//TODO: change name to useTargetSelector
export const useLocationSelector = create<LocationSelectorStore>((set) => ({
  enabled: false,
  setEnabled: (enabled: boolean) =>
    set(() => ({
      enabled,
    })),
  selectedLocation: TARGET_MAIN_OPTION.value,
  setSelectedLocation: (selectedLocation: string) =>
    set(() => ({
      selectedLocation,
    })),
  selectedLocationName: TARGET_MAIN_OPTION.label,
  setSelectedLocationName: (selectedLocationName: string) =>
    set(() => ({
      selectedLocationName,
    })),
  targetType: 'master',
  setTargetType: (targetType: TargetType) =>
    set(() => ({
      targetType,
    })),
}));
