import axios, { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { ResponseWithMessage } from './types';

const getStatusFallbackMessage = (status?: number) => {
  const errors: {
    [key: number]: string;
  } = {
    400: `Bad Request`,
    401: `Unauthorized`,
    403: `Access Denied`,
    404: `Not Found`,
    500: `Internal Server Error`,
    504: `Server Timeout`,
  };

  return status ? errors[status] : errors[500];
};

const getErrorMessage = (error: AxiosError<ResponseWithMessage>) => {
  const hasMessage = error?.response?.data?._message;

  if (hasMessage) {
    return error?.response?.data._message;
  }

  if (typeof error?.response?.data.errors === 'string') {
    return error.response.data.errors;
  }

  return getStatusFallbackMessage(error.response?.status || 500);
};

export const errorHandler = (error: AxiosError<ResponseWithMessage>) => {
  if (axios.isCancel(error)) return Promise.reject(error);

  const errorMessage = getErrorMessage(error);

  toast(errorMessage, { type: 'error', toastId: errorMessage });

  return Promise.reject(error);
};

export const errorHandlerNoToast = (error: AxiosError<ResponseWithMessage>) => {
  if (axios.isCancel(error)) return Promise.reject(error);
  return Promise.reject(error);
};
