import seedPlans from 'containers/dashboardUpdate/MembershipsPage/seedPlansCustom';
import {
  PresetType,
  Seed,
  TemplateName,
} from 'new/modules/MembershipPlansManage/types';
import {
  ManagePlan,
  ManagePlanDetail,
  ManagePlanDetailSL,
  PlanStatus,
  TabState,
} from './types';

export const NEW_TAB_ID = 100000000;

export const addNewPlan = (planTemplateName: string) => {
  const seedTemplatesPresetTypeConfig: Record<TemplateName, PresetType> = {
    'Adult Wellness Plan': 'general_adult',
    'Child Wellness Plan': 'general_child',
    'Perio Maintenance Plan': 'general_perio',
    'Toddler Plan': 'pediatric_toddler',
    'Child Plan': 'pediatric_child',
    'Teen Plan': 'pediatric_teen',
    'Perio 1x': 'periodontic_perio1',
    'Perio 2x': 'periodontic_perio2',
    'Perio 4x': 'periodontic_perio4',
  };

  const isTemplateWithPresetType =
    planTemplateName &&
    Object.keys(seedTemplatesPresetTypeConfig).includes(planTemplateName);

  const templateIndex = seedPlans.findIndex(
    (plan) => plan.name === planTemplateName,
  );

  const newInitialValues: ManagePlanDetail = {
    id: NEW_TAB_ID,
    planName: 'New Plan',
    showOnBC: true,
    description: '',
    locations: [],
    memberCount: { annually: 0, monthly: 0 },
    pricing: { annually: '0', monthly: '0' },
    updatedBy: undefined,
    limitType: '',
    groups: [],
    presetType: isTemplateWithPresetType
      ? seedTemplatesPresetTypeConfig[planTemplateName as TemplateName]
      : null,
    type: 'custom',
    yearly_id: 0, //TODO is this ok?
    minAge: null,
    maxAge: null,
    notes: '',
    priceCodes: [],
    subscription_age_group: 'adult',
    showOnEnrollmentAnnual: true,
    showOnEnrollmentMonthly: true,

    isDeleted: false,
    updatedAt: '',
    hasActiveMembers: false,
    worryFree: { year: 0, month: 0 },
    programName: '',
    programDescription: '',
    dentistSpecialtyId: 0,
    familyDiscounts: {
      family_discount_two: 0,
      family_discount_three: 0,
      family_discount_four: 0,
    },
    recommendedPricing: [],
    viewStatus: 'draft',
    showOnEnrollmentInternal: false,
    showOnEnrollmentExternal: false,
  };

  if (templateIndex >= 0) {
    const seed = (seedPlans as Seed[])[templateIndex];
    newInitialValues.planName = seed.name;
    newInitialValues.description = seed.description;
    newInitialValues.type = seed.type;
    newInitialValues.showOnBC = seed.showOnBC;
    newInitialValues.limitType = seed.limit_type;
    newInitialValues.subscription_age_group = seed.subscription_age_group;
    newInitialValues.showOnEnrollmentAnnual = seed.showOnEnrollmentAnnual;
    newInitialValues.showOnEnrollmentMonthly = seed.showOnEnrollmentMonthly;
    newInitialValues.minAge = seed.minAge;
    newInitialValues.maxAge = seed.maxAge;
  }

  return newInitialValues;
};

export const getDuplicateData = (
  planName?: string,
  data?: ManagePlanDetail,
  dataSingle?: ManagePlanDetailSL,
) => {
  if (data)
    return {
      ...data,
      planName,
    };
  if (dataSingle)
    return {
      ...dataSingle,
      planName,
    };
  return undefined;
};

export const getDuplicatePlanName = (
  originalName: string,
  manageTabState: Pick<TabState, 'planName'>[],
  plans: Pick<ManagePlan, 'planName'>[],
) => {
  let planName = `Copy of ${originalName}`;
  let existingNamesCount = manageTabState.reduce(
    (count, tab) => (tab.planName.includes(planName) ? count + 1 : count),
    0,
  );
  existingNamesCount = plans.reduce(
    (count, plan) => (plan.planName.includes(planName) ? count + 1 : count),
    existingNamesCount,
  );
  if (existingNamesCount) planName = `${planName} (${existingNamesCount})`;
  return planName;
};

export const viewStatusToStatusId = (viewStatus?: PlanStatus) => {
  switch (viewStatus) {
    case 'archived':
      return '1';
    case 'active':
      return '2';
    case 'inactive':
      return '3';
    case 'draft':
      return '4';
    default:
      return undefined;
  }
};

export const statusIdToViewStatus = (statusId?: string) => {
  switch (statusId) {
    case '1':
      return 'archived';
    case '2':
      return 'active';
    case '3':
      return 'inactive';
    case '4':
      return 'draft';
    default:
      return 'draft';
  }
};

export const getNewStatus = (viewStatus: string, status?: string) => {
  return viewStatus === 'archived'
    ? 'draft'
    : viewStatus === 'draft' && status === '4'
    ? 'active'
    : status === '1'
    ? 'archived'
    : status == '2'
    ? 'active'
    : status == '3'
    ? 'inactive'
    : 'draft';
};
