/*
NavBar Component
================================================================================
NOTE: Add custom left-hand-side elements for routes in the `returnLinks`
      dictionary (a class field).
*/

/*
Imports
------------------------------------------------------------
*/
// libs
import React from 'react';
import PropTypes from 'prop-types';
// import Image from 'react-bootstrap/lib/Image';
import Navbar from 'react-bootstrap/lib/Navbar';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { push } from 'react-router-redux';

// app
//import dentalhqLogo from 'new/assets/DHQ-logo_RGB_fullcolor-ondark.png';
import appIcon from 'new/assets/DHQ-logo_RGB_fullcolor-onlight.png';
// import ncdsLogo from 'assets/images/NC_In-House_Membership_Plan_RGB-60px.png';
// import ohLogo from 'assets/images/odasc.png';
// import eliteLogo from 'assets/images/EDA-DHQ-broughttoyou.png';
// import directLogo from 'assets/images/logo-directdental.png';
// import smilesourceLogo from 'assets/images/SS-DHQ-combo.png';
// import elevateLogo from 'assets/images/logo-elevateDHQ.png';
// import conciergeLogo from 'assets/images/logo-dentalconcierge.png';
// import softwarePunditLogo from 'assets/images/logo-SP-DHQ.png';
// import whiteboardLogo from 'assets/images/logo-whiteboard.png';
// import gargleLogo from 'assets/images/logo-gargle-h.png';
// import prsLogo from 'assets/images/logo-prs.png';
// import wdeLogo from 'assets/images/WDE-logo.png';

// local
import styles from './styles.module.css';
import {
  selectCurrentUser,
  selectAssociations,
} from 'containers/App/selectors';

/*
Redux
------------------------------------------------------------
*/
function mapStateToProps(state) {
  return {
    loggedInUser: selectCurrentUser(state),
    associations: selectAssociations(state),
    dentist_logo:
      state.smBusinessSignUp && state.smBusinessSignUp.accountInfo
        ? state.smBusinessSignUp.accountInfo.dentist_logo
        : state.completeBusinessSignupPage &&
          state.completeBusinessSignupPage.dentist_logo
        ? state.completeBusinessSignupPage.dentist_logo
        : null,
  };
}

/*
NavBar
================================================================================
*/
@connect(mapStateToProps, null)
@CSSModules(styles, { allowMultiple: true })
export default class NavBar extends React.Component {
  static propTypes = {
    changeRoute: PropTypes.func,
    loggedInUser: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
    pathname: PropTypes.string,
    logo: PropTypes.oneOfType([
      PropTypes.string, // show custom logo
      PropTypes.bool, // false => show no logo (i.e. custom logo url is loading)
    ]), // null => show dentalHQ logo
  };

  returnLinks = {
    '/accounts/dentist-signup': (
      <Link to="/" styleName="navbar__text">
        &lt; Home
      </Link>
    ),
    '/accounts/login': (
      <Link to="/accounts/login" styleName="navbar__text">
        &lt; Login
      </Link>
    ),
  };

  render() {
    let { logo, dentistLink, loggedInUser, associations } = this.props;

    if (loggedInUser.employees && loggedInUser.employees.length == 1) {
      const emp = loggedInUser.employees[0];
      logo =
        emp.SmbizBusinessInfo && emp.SmbizBusinessInfo.logo_url
          ? emp.SmbizBusinessInfo.logo_url
          : logo;
    }
    const { firstName, lastName, avatar } = this.props.loggedInUser;

    const fullName = `${firstName} ${lastName}`;
    const returnLink = this.returnLinks.hasOwnProperty(this.props.pathname)
      ? this.returnLinks[this.props.pathname]
      : null;

    let logoDisplay = null;
    let isAssociation = false;

    if (Array.isArray(associations) && associations?.length !== 0) {
      associations.map((assoc) => {
        if (this.props.pathname.indexOf(assoc.signupURL) != -1) {
          logoDisplay = (
            <Link to="/" styleName="navbar__brand__link">
              <img
                src={assoc.logo}
                alt={assoc.name}
                styleName="navbar__brand__img"
              />
            </Link>
          );
          isAssociation = true;
        }
      });
    }

    if (isAssociation == false) {
      if (logo === null || logo === undefined) {
        // show dentalHQ logo
        logoDisplay = (
          <Link
            to={
              dentistLink
                ? dentistLink.indexOf('http') != -1
                  ? dentistLink
                  : 'http://' + dentistLink
                : 'https://dentalhq.com'
            }
            styleName="navbar__brand__link"
          >
            <img
              src={
                this.props.dentist_logo != null
                  ? this.props.dentist_logo
                  : appIcon
              }
              alt="DentalHQ"
              styleName="navbar__brand__img"
              //style={{ width: 230, height: 87, marginTop: -25 }}
            />
          </Link>
        );
      } else if (logo !== false) {
        // show custom logo
        logoDisplay = (
          <a
            href={
              dentistLink
                ? dentistLink.indexOf('http') != -1
                  ? dentistLink
                  : 'http://' + dentistLink
                : 'https://dentalhq.com'
            }
            styleName="navbar__brand__link"
          >
            <img src={logo} alt="Dentist Logo" styleName="navbar__brand__img" />
          </a>
        );
      }
    }

    return (
      <Navbar fixedTop styleName="navbar">
        <div className="row" styleName="navbar__row">
          <div className="col-sm-3" styleName="navbar__col">
            {(this.props.pathname.indexOf('complete-dentist') != -1 ||
              this.props.pathname.indexOf('signup-complete') != -1) &&
              logoDisplay}
            {returnLink}
          </div>

          <div className="col-xs-12 col-sm-6" styleName="navbar__col">
            <div styleName="navbar__brand">
              {this.props.pathname.indexOf('complete-dentist') == -1 &&
                this.props.pathname.indexOf('signup-complete') == -1 &&
                logoDisplay}
            </div>
          </div>
        </div>
      </Navbar>
    );
  }
}
