import { Box, Stack, Typography, styled } from '@mui/material';
import { history } from 'app';
import moment from 'moment';
import Button from 'new/components/Button/Button';
import IconFontAwesome from 'new/components/IconFontAwesome';
import LinkButton from 'new/components/LinkButton';
import Modal from 'new/components/Modal';
import { ModalContent } from 'new/components/ModalContent/styles';
import useActiveOffice from 'new/hooks/useActiveOffice';
import { SEASON_OF_GIVING_LAST_DAY } from 'new/modules/AnalyticDashboardPage/constants';
import findRoute from 'new/routing/routes';
import React, { useEffect, useMemo, useState } from 'react';
import Cookies from 'universal-cookie';
import { useSeasonOfGiving } from '../hooks';
const cookies = new Cookies();

const Icons = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(2.1),
  width: '100%',
  flexWrap: 'wrap',
  opacity: 0.7,
}));

// const DollarIcon = styled(Box)(({ theme }) => ({
//   fontFamily: 'Termina',
//   fontSize: '1.875rem',
//   lineHeight: '2.25rem',
//   margin: theme.spacing(0, 0, 0.5, 0.75),
//   color: theme.palette.common.white,
//   [theme.breakpoints.between('xl', 1660)]: {
//     fontSize: '1.5rem',
//     lineHeight: '2rem',
//   },
// }));

const Content = styled(ModalContent)(({ theme }) => ({
  background: theme.palette.dhqLighterBlue.light,
  padding: theme.spacing(2),
  width: 535,
  position: 'relative',
}));

const WinText = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: 126,
  left: 60,
  background: theme.palette.dhqLighterBlue.light,
  p: {
    color: theme.palette.dhqBlack.main,
  },
}));

const Price = styled(Typography)(({ theme }) => ({
  color: `${theme.palette.dhqYellow.main}!important`,
  fontFamily: 'Termina',
  fontWeight: 'bold',
}));

// const DentalHQs = styled(Typography)(({ theme }) => ({
//   background: theme.palette.dhqBlue.light,
//   position: 'absolute',
//   top: 240,
//   left: 318,
//   paddingTop: 13,
//   fontSize: '1.25rem',
//   fontFamily: 'Epilogue',
// }));

// const Title = styled(Box)(({ theme }) => ({
//   fontFamily: 'Termina',
//   color: theme.palette.common.white,
//   fontSize: '2.25rem',
//   fontWeight: 'bold',
//   textAlign: 'right',
//   background: theme.palette.dhqBlue.light,
//   position: 'absolute',
//   paddingLeft: 5,
//   paddingBottom: 10,
//   top: 280,
//   left: 270,
// }));

const ButtonWrapper = styled(Box)(({ theme }) => ({
  background: theme.palette.dhqLighterBlue.light,
  position: 'absolute',
  top: 396,
  left: 310,
  paddingBottom: 10,
  button: {
    height: 40,
  },
}));

const BLACKLIST_PAGES = [
  '/admin',
  '/accounts',
  '/enroll',
  '/season-of-giving',
  '/single-setup',
  '/office-enroll',
  '/checkout',
  '/subscribe',
  '/multi-setup',
  '/multi-csv-review',
  '/businessconnect',
  '/patient',
  '/pn',
  '/out-of-network',
  '/ppo-calculator',
  '/community-enroll',
  '/signup',
  '/start',
  '/associations',
  '/BAA-signed',
  '/baa-signed',
  '/business',
  '/faq',
  '/search',
  '/privacy',
  '/terms',
  '/external-patient-signup',
  '/error',
  '/patients',
];

const SeasonOfGivingModal: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [pagePath, setPagePath] = useState(
    history.getCurrentLocation().pathname,
  );
  const { user, refetch } = useActiveOffice();

  useEffect(() => {
    const unlisten = history.listen(() => {
      setPagePath(history.getCurrentLocation().pathname);
    });
    return () => {
      unlisten();
    };
  }, []);

  const isPostponed = useMemo(() => {
    if (!user || !user?.data.seasonOfGivingLastSeen) return false;
    return moment(user?.data.seasonOfGivingLastSeen)
      .add(14, 'day')
      .isAfter(moment());
  }, [user]);

  useEffect(() => {
    const isDisabled = !moment().isSameOrBefore(
      SEASON_OF_GIVING_LAST_DAY,
      'day',
    );
    const isBlacklistPage = BLACKLIST_PAGES.some((page) =>
      pagePath.includes(page),
    );

    if (
      !isDisabled &&
      !!user &&
      !isBlacklistPage &&
      !cookies.get('seasonOfGivingAcknowledged') &&
      !cookies.get('beccascookie') &&
      !isPostponed
    ) {
      setIsOpen(true);
    }
  }, [user, isPostponed, pagePath]);

  const { mutate: acknowledge } = useSeasonOfGiving();

  const { getPath } = findRoute('SeasonOfGivingPage');
  const seasonOfGivingPath = getPath ? getPath() : '';

  const onCloseTwoWeeks = () => {
    setIsOpen(false);
    acknowledge(undefined, {
      onSuccess: () => refetch(),
    });
  };

  const onClose = () => {
    setIsOpen(false);
    cookies.set('seasonOfGivingAcknowledged', true, { path: '/' });
  };

  const onAction = () => {
    onClose();
    history.push(seasonOfGivingPath);
  };

  return (
    <Modal open={isOpen} onClose={onClose} sx={{ padding: 0 }}>
      <Content>
        <Icons>
          {Array.from({ length: 20 }, (_, i) => i).map((index) => (
            <React.Fragment key={index}>
              <img
                src="https://dentalhq-uploads.s3.amazonaws.com/Money+Flower+Icon.png"
                height="40px"
              />
              <img
                src="https://dentalhq-uploads.s3.amazonaws.com/Money+Flower+Icon.png"
                height="40px"
              />
              <img
                src="https://dentalhq-uploads.s3.amazonaws.com/Money+Flower+Icon.png"
                height="40px"
              />
              <img
                src="https://dentalhq-uploads.s3.amazonaws.com/Money+Flower+Icon.png"
                height="40px"
              />
            </React.Fragment>
          ))}
        </Icons>

        <WinText>
          <Stack direction="row">
            <Typography
              fontFamily="Termina"
              fontWeight="bold"
              fontSize="3.5rem"
              lineHeight="3.5rem"
            >
              Grow your
              <br />
              membership
            </Typography>
          </Stack>
          <Stack direction="row">
            <Typography
              fontFamily="Epilogue"
              fontSize="2.75rem"
              lineHeight="2.5rem"
              fontWeight="bold"
            >
              and win up to
            </Typography>
          </Stack>
          <Stack direction="row">
            <Price fontSize="4.875rem" lineHeight="4.875rem">
              $500
            </Price>
          </Stack>
        </WinText>
        <ButtonWrapper>
          <Button
            color="warning"
            endIcon={<IconFontAwesome name="faArrowRight" color="blueLight" />}
            onClick={onAction}
          >
            Yes please
          </Button>
        </ButtonWrapper>
      </Content>
      <Stack direction="row" justifyContent="space-between" py={1} px={0.5}>
        <LinkButton onClick={onCloseTwoWeeks}>
          {moment().isAfter(
            moment(SEASON_OF_GIVING_LAST_DAY).add(-14, 'days'),
            'day',
          )
            ? "Don't show this again"
            : "Close window and don't show this again for 2 weeks"}
        </LinkButton>
        <LinkButton onClick={onClose}>Close window</LinkButton>
      </Stack>
    </Modal>
  );
};
export default SeasonOfGivingModal;
