import { api } from 'new/api/axios';
import { createEndpoint } from 'new/api/utils';
import {
  AssignPlansRequest,
  ChangeTargetRequest,
  ChangeTargetResponse,
  GetLegacyPlansListRequest,
  GetLegacyPlansListResponse,
  GetMultiPlanSettingsRequest,
  GetPlanSettingsRequest,
  GuaranteedPlanPaymentsRequest,
  GuaranteedPlanPaymentsToggleRequest,
  ManagePlan,
  ManagePlanDetail,
  ManagePlansSettings,
  MergeLegacyPlansRequest,
  RemoveAssignPlansRequest,
  SavePlanSpanishRequest,
  SetGlobalPlanSettingsRequest,
  UpdatePlanPricingMultiRequest,
  UpdatePlanPricingMultiResponse,
  UpdatePlanStatusMultiRequest,
  WorryFreeResponse,
} from 'new/modules/ManagePlans/types';
import { ManageUserAssignments } from 'new/modules/ManageUsers/types';
import {
  EditPlanRequest,
  EditPlanResponse,
  MembershipSettingsRequest,
  Plan,
} from '../MembershipPlansManage/types';

export const getPlansList = createEndpoint<ManagePlan[], unknown>({
  url: '/multi-location/manage-plans/:id',
  endpoint: ({ url }) => api.get<ManagePlan[]>(url),
});

export const getSimplePlansList = createEndpoint<
  { custom_memberships: Plan[] },
  unknown
>({
  url: '/users/:id/dentist-info/all-plans/upgrade',
  endpoint: ({ url }) => api.post<{ custom_memberships: Plan[] }>(url),
});

export const getGroupPlansList = createEndpoint<ManagePlan[], unknown>({
  url: '/multi-location/group-plans/:id',
  endpoint: ({ url }) => api.get<ManagePlan[]>(url),
});

///
// export const changeMasterPlanSortOrder = createEndpoint<
//   undefined,
//   Omit<{order: String[], organizationId : Number}, 'dentistInfoId'>
// >({
//   url: '/multi-location/sort-master-plan',
//   endpoint: ({ url, params }) =>
//     api.post<any>(url, params),
// });

export const changeMasterPlanSortOrder = createEndpoint<unknown>({
  url: '/multi-location/sort-master-plan',
  endpoint: ({ url, params }) => api.post<unknown>(url, params),
});

export const changeMembershipSettingsML =
  createEndpoint<MembershipSettingsRequest>({
    url: '/multi-location/organization-plan-settings',
    endpoint: ({ url, params }) =>
      api.post<MembershipSettingsRequest>(url, params),
  });

export const getGlobalSettingsUcrs = () =>
  createEndpoint<ManagePlansSettings, GetPlanSettingsRequest>({
    url: `/multi-location/global-plan-settings`,
    endpoint: ({ url, params }) => api.post<ManagePlansSettings>(url, params),
  });

export const getMultiPlanSettings = () =>
  createEndpoint<ManagePlansSettings, GetMultiPlanSettingsRequest>({
    url: `/multi-location/multi-plan-settings`,
    endpoint: ({ url, params }) => api.post<ManagePlansSettings>(url, params),
  });

export const getPlanDetail = createEndpoint<
  ManagePlanDetail & {
    showOnInternalEnrollment: boolean;
    showOnEnrollment: boolean;
  }
>({
  url: '/dentists/me/custom-memberships/manage-plans/:id',
  endpoint: ({ url }) =>
    api.get<
      ManagePlanDetail & {
        showOnInternalEnrollment: boolean;
        showOnEnrollment: boolean;
      }
    >(url),
});

export const getMasterPlanDetail = createEndpoint<ManagePlanDetail>({
  url: '/multi-location/master-plan-details/:id',
  endpoint: ({ url }) => api.get<ManagePlanDetail>(url),
});

export const assignPlans = createEndpoint<unknown, AssignPlansRequest>({
  url: '/multi-location/assign-plans/:id',
  endpoint: ({ url, params }) => api.post<ManageUserAssignments>(url, params),
});

export const updatePlanStatus = createEndpoint<UpdatePlanStatusMultiRequest>({
  url: '/multi-location/multi-master-plan-save-state',
  endpoint: ({ url, params }) =>
    api.post<UpdatePlanStatusMultiRequest>(url, params),
});

export const updatePlanPricing = createEndpoint<
  UpdatePlanPricingMultiResponse,
  UpdatePlanPricingMultiRequest
>({
  url: 'multi-location/change-master-plan-price',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const removeAssignPlans = createEndpoint<
  unknown,
  RemoveAssignPlansRequest
>({
  url: '/multi-location/assign-plans/:id',
  endpoint: ({ url, params }) =>
    api.delete<RemoveAssignPlansRequest>(url, { data: params }),
});

export const setPlanSettings = createEndpoint<
  unknown,
  SetGlobalPlanSettingsRequest
>({
  url: '/dentists/updateMembershipSettings',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const getLegacyPlansList = createEndpoint<
  GetLegacyPlansListResponse,
  GetLegacyPlansListRequest
>({
  url: '/multi-location/get-legacy-org-plans',
  endpoint: ({ url, params }) =>
    api.post<GetLegacyPlansListResponse>(url, params),
});

export const mergeLegacyPlans = createEndpoint<
  unknown,
  MergeLegacyPlansRequest
>({
  url: '/multi-location/merge-legacy-plans',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const editPlan = createEndpoint<EditPlanResponse, EditPlanRequest>({
  url: '/dentists/:id/custom-memberships',
  endpoint: ({ url, params }) => api.patch(url, params),
});

export const setGuaranteedPlanPaymentsEndpoint = createEndpoint<
  unknown,
  GuaranteedPlanPaymentsRequest
>({
  url: '/dentists/guaranteed-plan-payments',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const toggleGuaranteedPlanPaymentsEndpoint = createEndpoint<
  unknown,
  GuaranteedPlanPaymentsToggleRequest
>({
  url: '/dentists/guaranteed-plan-payments/toggle',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const changeTargetEndpoint = createEndpoint<
  ChangeTargetResponse,
  ChangeTargetRequest
>({
  url: '/multi-location/change-target',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const getDentistWorryFreeEndpoint = createEndpoint<WorryFreeResponse>({
  url: '/dentists/:id/custom-memberships/getDentistWorryFree',
  endpoint: ({ url }) => api.get<WorryFreeResponse>(url),
});

export const savePlanSpanish = createEndpoint<SavePlanSpanishRequest>({
  url: '/multi-location/spanish-plan-details/:id',
  endpoint: ({ url, params }) => api.post<SavePlanSpanishRequest>(url, params),
});
