import React from 'react';
import {
  createTheme,
  ThemeOptions,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';

export const themeConf: ThemeOptions = {
  palette: {
    primary: {
      main: '#00A1DF',
      light: '#E6F6FC',
      dark: '#024C64',
      contrastText: '#fff',
    },
    secondary: {
      light: '#E2E2E2',
      main: '#9F9F9F',
      dark: '#707070',
    },
    error: {
      main: '#D63A4A',
    },
    warning: {
      light: '#F8E90B',
      main: '#FFE500',
    },
    success: {
      light: '#22E812',
      main: '#46CF88',
    },
    grey: {
      200: '#FAFAFA', // very light grey
      300: '#E3E3E3', // light grey
      400: '#BFBFBF', // medium grey
      500: '#DDD',
      600: '#777', // grey
      800: '#383838', // dark grey
    },
    blueGrey: {
      300: '#d2dfe5',
      400: '#c0d7e3',
      600: '#357083',
    },
    dhqBlack: { main: '#101820', light: '#40464D' },

    dhqBlue: {
      main: '#004680',
      dark: '#024C64',
      light: '#00CCFF',
    },
    dhqGray: { main: '#41464C' },
    dhqYellow: { main: '#F8E90B', light: '#F8E90B' },
    dhqLighterBlue: { light: '#00A1DF', main: '#E1F1F7', dark: '#CAE9F5' },
  },
  typography: {
    h1: {
      fontFamily: 'Rubik',
      fontWeight: 700,
      color: '#00A1DF',
    },
    h2: {
      fontFamily: 'Rubik',
      fontWeight: 700,
      color: '#00A1DF',
    },
    h3: {
      fontFamily: 'Rubik',
      fontWeight: 700,
      color: '#00A1DF',
    },
    h4: {
      fontFamily: 'Barlow',
      color: '#00A1DF',
    },
    body1: {
      fontFamily: 'Barlow',
    },
    body2: {
      fontFamily: 'Barlow',
    },
    button: {
      fontFamily: 'Barlow',
    },
  },
  spacing: 8,
};

const ThemeProvider: React.FC = ({ children }) => (
  <MuiThemeProvider theme={createTheme(themeConf)}>{children}</MuiThemeProvider>
);

export default ThemeProvider;
