import { history } from 'app';
import { AxiosResponse } from 'axios';
import { Id } from 'new/api/types';
import findRoute from 'new/routing/routes';
import { useCallback, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import { getItem, setItem } from 'utils/localStorage';
import {
  getUserFromToken,
  submitConfirmationCode,
  submitEmailAddress,
  validateCouponCode,
} from './endpoints';
import {
  ChosenPlan,
  CodeSubmitResponse,
  EmailFormValues,
  MembershipModalFormValues,
  MembershipModalSubmitValues,
  OnStandardPricingSelection,
} from './types';

const cookies = new Cookies();

const USER_REFETCH_INTERVAL = 60000;

export const useEmailAddressMutation = () =>
  useMutation<unknown, unknown, EmailFormValues>((value) =>
    submitEmailAddress.endpoint(value),
  );

export const useValidateCoupon = (promo = '') =>
  useQuery({
    queryKey: [`getCoupon`, promo],
    queryFn: () =>
      validateCouponCode
        .endpoint({ discountCode: promo })
        .then((res) => res.data),
    enabled: !!promo?.length,
  });

export const useConfirmationCodeMutation = () =>
  useMutation<
    AxiosResponse<CodeSubmitResponse>,
    unknown,
    MembershipModalSubmitValues
  >((value) => submitConfirmationCode.endpoint(value));

export const useGetUserFromToken = (
  withRefetch?: boolean,
  dentistInfoId?: Id,
  queryKey?: string,
) => {
  const token = getItem('auth_token');
  const activeOfficeId = cookies.get('officeId');

  return useQuery({
    queryKey: [
      queryKey ? `${queryKey} ${token}` : `USER_FROM_TOKEN: ${token}`,
      dentistInfoId || activeOfficeId,
    ],
    queryFn: () =>
      getUserFromToken
        .endpoint({ dentistInfoId: dentistInfoId || activeOfficeId })
        .then((res) => res.data),
    enabled: !!token,
    staleTime: Infinity,
    retry: 0,
    refetchInterval: withRefetch ? USER_REFETCH_INTERVAL : undefined,
  });
};

export const useEnrollmentInit = (
  promo?: string,
  chosenPlanInit?: ChosenPlan,
) => {
  const {
    mutate: submitEmail,
    isLoading: isSubmittingEmail,
    reset: resetEmail,
  } = useEmailAddressMutation();
  const {
    mutate: submitCode,
    isLoading: isSubmittingCode,
    reset: resetCode,
  } = useConfirmationCodeMutation();
  const [chosenPlan, setChosenPlan] = useState<ChosenPlan | undefined>(
    chosenPlanInit,
  );
  const { data: promoData, isLoading: isLoadingCoupon } =
    useValidateCoupon(promo);

  const handlePricingSelection: OnStandardPricingSelection = (
    tierLevel,
    pricingPeriod,
  ) => {
    setChosenPlan({
      tierLevel,
      pricingPeriod,
    });
  };

  const handleEmailSubmit = (
    { email }: MembershipModalFormValues,
    onSuccess?: () => void,
  ) => {
    submitEmail(
      { email },
      {
        onSuccess,
      },
    );
  };

  const handleCodeSubmit = useCallback(
    (values: MembershipModalFormValues) => {
      if (!chosenPlan) {
        toast.error('Please select your plan first');
        return;
      }
      const submitValues = {
        ...values,
        ...chosenPlan,
        code: values.code.join(''),
        discountCode: promo ?? null,
      };

      submitCode(submitValues, {
        onSuccess: (res) => {
          const enrollPath = findRoute('EnrollPage').path;
          const { token } = res.data;

          setItem('auth_token', token);
          history.push(enrollPath);
        },
      });
    },
    [chosenPlan, promo],
  );

  const handleClose = () => {
    setChosenPlan(undefined);
    resetEmail();
    resetCode();
  };

  return {
    handlePricingSelection,
    chosenPlan,
    handleClose,
    handleEmailSubmit,
    handleCodeSubmit,
    isSubmittingEmail,
    isSubmittingCode,
    promoData,
    isLoadingCoupon,
  };
};
