/*
NavBar Component
================================================================================
NOTE: Add custom left-hand-side elements for routes in the `returnLinks`
      dictionary (a class field).
*/

/*
Imports
------------------------------------------------------------
*/
// libs
import React from 'react';
import PropTypes from 'prop-types';
// import Image from 'react-bootstrap/lib/Image';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { push } from 'react-router-redux';
import FaFile from 'react-icons/lib/fa/file';
import FaUserMd from 'react-icons/lib/fa/user-md';
import FaWrench from 'react-icons/lib/fa/wrench';
import FaMsg from 'react-icons/lib/fa/envelope';
import FaUserSecret from 'react-icons/lib/fa/user-secret';
import FaUserPlus from 'react-icons/lib/fa/user-plus';
import FaBriefcase from 'react-icons/lib/fa/briefcase';
import FaBarChart from 'react-icons/lib/fa/bar-chart';
import FaBuilding from 'react-icons/lib/fa/building';
import NewSectionIcon from 'react-icons/lib/fa/exclamation';
import FaExclamationTriangle from 'react-icons/lib/fa/exclamation-triangle';
import "./sidenav.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFileSpreadsheet } from '@fortawesome/pro-regular-svg-icons';


import StatsModal from 'components/StatsModal';

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';

import InviteModal from 'components/InviteModal';
import ShowStripeModal from 'components/ShowStripeModal';
import { getItem, removeItem } from 'utils/localStorage';

// app
import dentalhqLogo from 'new/assets/DHQ-logo_RGB_fullcolor-ondark.png';
import { selectCurrentUser } from 'containers/App/selectors';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

// local
import styles from './styles.module.css';

import {
  // fetch
  fetchStats,


} from 'containers/AdminDentistsPage/actions';
import {
  // fetch

  selectStats,

} from 'containers/AdminDentistsPage/selectors';

/*
Redux
------------------------------------------------------------
*/
function mapStateToProps (state) {
  return {
    loggedInUser: selectCurrentUser(state),
    user: selectCurrentUser(state),
    stats: selectStats(state)
  };
}

function mapDispatchToProps (dispatch) {
  return {
    changeRoute: (url) => dispatch(push(url)),
    fetchStats: () => dispatch(fetchStats()),
  }
}


/*
NavBar
================================================================================
*/
@connect(mapStateToProps, mapDispatchToProps)
@CSSModules(styles, { allowMultiple: true })
export default class SideNavAdmin extends React.Component {

  static propTypes = {
    changeRoute: PropTypes.func,
    loggedInUser: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ]),
    pathname: PropTypes.string,
    user: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
    ]),
    stats: PropTypes.object,
    fetchStats: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props);

    this.state = {
      showStats: false,
    };
  }

  // UNSAFE_componentWillMount () {
  //     this.props.fetchStats();
  // }


  selectTab = (key) => {
    switch (key) {
      case 'services':
        this.props.changeRoute('/admin/services');
        break;
      case 'toolbox':
        this.props.changeRoute('/admin/toolbox');
        break;
      case 'edit-profile':
        this.props.changeRoute('/admin/edit-profile');
        break;
      case 'members':
        this.props.changeRoute('/admin/members');
        break;
      case 'managers':
        this.props.changeRoute('/admin/manage');
        break;
      case 'reports':
        this.props.changeRoute('/admin/reports');
        break;
      case 'profile':
        this.props.changeRoute('/admin/profile');
        break;
      case 'dentists':
        this.props.changeRoute('/admin/dentists');
        break;
      case 'super_admin':
          this.props.changeRoute('/admin/super_admin');
          break;
      case 'almost_super':
        this.props.changeRoute('/admin/more');
        break;
      case 'fees':
        this.props.changeRoute('/admin/fees');
        break;
      case 'promos':
        this.props.changeRoute('/admin/promos');
        break;
      case 'associations':
        this.props.changeRoute('/admin/associations');
        break;
      case 'kpis':
        this.props.changeRoute('/admin/kpi');
        break;
      case 'errors':
        this.props.changeRoute('/admin/errorLogs');
        break;
      case 'business-connect':
        this.props.changeRoute('/admin/business-connect');
        break;
      case 'leadsource':
        this.props.changeRoute('/admin/leadSource');
        break;
      case 'release_notes':
        this.props.changeRoute('/admin/releaseNotes');
        break;
      case 'smsSetup':
        this.props.changeRoute('/admin/smsSetup');
        break;
      case 'smsLogs':
        this.props.changeRoute('/admin/smsLogs');
        break;
      default:
        // Don't do anything... we should never actually reach here.
        break;
    }
  }

  toggleStats=()=>{
    this.setState({showStats:!this.state.showStats});
  }

  /*
  Component Actions
  ------------------------------------------------------------
  */

  render () {
      const { firstName, lastName, avatar, email,type} = this.props.loggedInUser;
      const jwt = getItem('auth_token');


    // else - show no logo

      return (
        <div styleName="sidebar_admin" className='content_div_inner'>
          <Accordion>
            <AccordionItem onClick={() =>this.selectTab('dentists')}  >
              <AccordionItemTitle styleName={this.props.pathname.indexOf('admin/dentists')!=-1?"on_dashboard":""}>
              <FaUserMd/>&emsp;Dentists
              </AccordionItemTitle>
            </AccordionItem>
            <AccordionItem onClick={() =>this.selectTab('members')}  >
              <AccordionItemTitle styleName={this.props.pathname.indexOf('admin/members')!=-1&&this.props.pathname.indexOf('memberships')==-1?"on_dashboard":""}>
              <FaUserPlus/>&emsp;Members
              </AccordionItemTitle>
            </AccordionItem>
            <AccordionItem  onClick={() =>this.selectTab('reports')}>
                <AccordionItemTitle >
                    <FaFile/>&emsp;Reports
                </AccordionItemTitle>
                {/* <AccordionItemBody>
                <ul>
                <li>
              <a onClick={() =>this.selectTab('reports')} aria-selected={this.props.pathname.indexOf('reports')!=-1?"true":"false"}>
                  - Master Reports
                </a>
              </li>
                <li>
              <a onClick={() =>this.selectTab('payment-history')} aria-selected={this.props.pathname.indexOf('payment-history')!=-1?"true":"false"}>
                  - Payment Ledger
                </a>
              </li>
                  </ul>
                  </AccordionItemBody> */}
            </AccordionItem>
            {/* <AccordionItem onClick={() =>this.selectTab('services')}  >
              <AccordionItemTitle styleName={this.props.pathname.indexOf('admin/services')!=-1?"on_dashboard":""}>
              <FaList/>&emsp;Services
              </AccordionItemTitle>
            </AccordionItem>
            <AccordionItem onClick={() =>this.selectTab('toolbox')}  >
              <AccordionItemTitle styleName={this.props.pathname.indexOf('admin/toolbox')!=-1?"on_dashboard":""}>
              <FaWrench/>&emsp;Toolbox
              </AccordionItemTitle>
            </AccordionItem> */}
            <AccordionItem onClick={() =>this.selectTab('managers')}  >
              <AccordionItemTitle styleName={this.props.pathname.indexOf('admin/manage')!=-1?"on_dashboard":""}>
              <FaUserSecret/>&emsp;DentalHQ Users
              </AccordionItemTitle>
            </AccordionItem>
            <AccordionItem onClick={() =>this.selectTab('associations')}  >
              <AccordionItemTitle styleName={this.props.pathname.indexOf('admin/associations')!=-1?"on_dashboard":""}>
              <FaBuilding/>&emsp;Associations
              </AccordionItemTitle>
            </AccordionItem>
            <AccordionItem onClick={() =>this.selectTab('business-connect')}  >
              <AccordionItemTitle styleName={this.props.pathname.indexOf('admin/business-connect')!=-1?"on_dashboard":""}>
              <FaBriefcase/>&emsp;Small Businesses
              </AccordionItemTitle>
            </AccordionItem>
            <AccordionItem onClick={() =>this.selectTab('kpis')}  >
              <AccordionItemTitle>
              <FaBarChart/>&emsp;KPIs
              </AccordionItemTitle>
            </AccordionItem>
            {(type=='almost_super'||type=='super_admin')&&(
            <AccordionItem onClick={() =>this.selectTab('almost_super')}  >
              <AccordionItemTitle styleName={this.props.pathname.indexOf('admin/almost_super')!=-1?"on_dashboard":""}>
              <FaWrench/>&emsp;Admin
              </AccordionItemTitle>
            </AccordionItem>
            )}
            {type=='super_admin'&&(
            <AccordionItem onClick={() =>this.selectTab('super_admin')}  >
              <AccordionItemTitle styleName={this.props.pathname.indexOf('admin/super_admin')!=-1?"on_dashboard":""}>
              <FaWrench/>&emsp;Super Admin
              </AccordionItemTitle>
            </AccordionItem>
            )}
            {(type=='almost_super'||type=='super_admin')&&(
              <>
            <AccordionItem onClick={() =>this.selectTab('smsSetup')}  >
              <AccordionItemTitle styleName={this.props.pathname.indexOf('admin/smsSetup')!=-1?"on_dashboard":""}>
              <FaMsg/>&emsp;SMS Setup
              </AccordionItemTitle>
            </AccordionItem>
            <AccordionItem onClick={() =>this.selectTab('smsLogs')}  >
              <AccordionItemTitle styleName={this.props.pathname.indexOf('admin/smsLogs')!=-1?"on_dashboard":""}>
              <FaFile/>&emsp;SMS Report
              </AccordionItemTitle>
            </AccordionItem>
            </>
            )}
            {/* {(type=='super_admin'||type=='admin')&&(
            <AccordionItem onClick={() =>this.selectTab('fees')}  >
              <AccordionItemTitle styleName={this.props.pathname.indexOf('admin/fees')!=-1?"on_dashboard":""}>
              <FaWrench/>&emsp;Pause Minimum
              </AccordionItemTitle>
            </AccordionItem>
            )} */}

            
            <AccordionItem onClick={() =>this.selectTab('promos')}  >
              <AccordionItemTitle styleName={this.props.pathname.indexOf('admin/promos')!=-1?"on_dashboard":""}>
              <FaWrench/>&emsp;Promo Codes
              </AccordionItemTitle>
            </AccordionItem>
            

          
            <AccordionItem onClick={() =>this.selectTab('errors')}  >
              <AccordionItemTitle styleName={this.props.pathname.indexOf('admin/errorLogs')!=-1?"on_dashboard":""}>
              <FaExclamationTriangle/>&emsp;Error Logs
              </AccordionItemTitle>
            </AccordionItem>

                      
            <AccordionItem onClick={() =>this.selectTab('leadsource')}  >
              <AccordionItemTitle styleName={this.props.pathname.indexOf('admin/leadSource')!=-1?"on_dashboard":""}>
              <FaBriefcase/>&emsp;Lead Sources
              </AccordionItemTitle>
            </AccordionItem>
            
            {/* <AccordionItem onClick={() =>this.selectTab('release_notes')}  >
              <AccordionItemTitle styleName={this.props.pathname.indexOf('admin/releaseNotes')!=-1?"on_dashboard":""}>
              <FaBriefcase/>&emsp;Release Notes
              </AccordionItemTitle>
            </AccordionItem> */}
   
            



      </Accordion>
      <StatsModal
            show={this.state.showStats}
            onCancel={this.toggleStats}
            stats={this.props.stats}
            gotoKPIs={() =>this.selectTab('kpis')}
          />
     </div>
      );
  }
}
