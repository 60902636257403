const SeedPlansCustom = [
  {
    name: 'Adult Wellness Plan',
    description:
      '1 routine cleaning every 6 months\nRegular exam with cleaning\nOral cancer screening with cleaning\n1 set of routine X-rays per year\n1 emergency exam with X-ray per year\nUp to __% off additional treatment',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'general_adult',
    recommendedPricingFormula: [
      ['1110', 2],
      ['0120', 2],
      ['0274', 1],
      ['0220', 2],
    ], // (D1110 x 2) + (D0120 x 2) + D0274 + (D0220 x 2)
  },
  {
    name: 'Child Wellness Plan',
    description:
      '1 routine cleaning every 6 months\nFluoride treatment with cleaning\nRegular exam with cleaning\nOral cancer screening with cleaning\n1 set of routine X-rays per year\n1 emergency exam with X-ray per year\nUp to __% off additional treatment',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: true,
    whitening: false,
    teledentistry: false,
    maxAge: 13,
    minAge: null,
    has_limit: true,
    ageLimit: null,
    limit_type: 'under',
    age_limit: 'under',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'general_child',
    recommendedPricingFormula: [
      ['1120', 2],
      ['0120', 2],
      ['0272', 1],
    ], // (D1120 x 2) + (D0120 x 2) + D0272
  },
  {
    name: 'Perio Maintenance Plan',
    description:
      '1 periodontal maintenance every 3 months\n' +
      'Fluoride treatment with cleaning\n' +
      '2 regular exams per year\n' +
      'Oral cancer screening with cleaning\n' +
      '1 set of routine X-rays per year\n' +
      '1 emergency exam with X-ray per year\n' +
      'Up to __% off additional treatment\n',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: '',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'general_perio',
    recommendedPricingFormula: [
      ['4910', 4],
      ['0120', 2],
      ['0274', 1],
      ['0220', 2],
    ], // (D4910 x 4) + (D0120 x 2) + D0274 + (D0220 x 2)
  },
  {
    name: 'Toddler Plan',
    description:
      '1 routine cleaning every 6 months\n' +
      'Fluoride treatment with cleaning\n' +
      'Regular exam with cleaning\n' +
      '1 set of routine X-rays per year\n' +
      '1 emergency exam with X-ray per year\n' +
      'Up to __% off additional treatment\n',
    yearly_membership: [],
    subscription_age_group: 'child',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: 3,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'under',
    age_limit: 'under_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'pediatric_toddler',
    recommendedPricingFormula: [
      ['1120', 2],
      ['0145', 2],
      ['0272', 1],
    ], // (D1120 x 2) + (D0145 x 2) + D0272
  },
  {
    name: 'Child Plan',
    description:
      '1 routine cleaning every 6 months\n' +
      'Fluoride treatment with cleaning\n' +
      'Regular exam with cleaning\n' +
      'Oral cancer screening with cleaning\n' +
      '1 set of routine X-rays per year\n' +
      '1 emergency exam with X-ray per year\n' +
      'Up to __% off additional treatment\n',
    yearly_membership: [],
    subscription_age_group: 'child',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: 12,
    minAge: 4,
    has_limit: false,
    ageLimit: null,
    limit_type: 'between',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'pediatric_child',
    recommendedPricingFormula: [
      ['1120', 2],
      ['0120', 2],
      ['0272', 1],
    ], // (D1120 x 2) + (D0120 x 2) + D0272
  },
  {
    name: 'Teen Plan',
    description:
      '1 routine cleaning every 6 months\n' +
      'Fluoride treatment with cleaning\n' +
      'Regular exam with cleaning\n' +
      'Oral cancer screening with cleaning\n' +
      '1 set of routine X-rays per year\n' +
      '1 emergency exam with X-ray per year\n' +
      'Up to __% off additional treatment\n',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: 13,
    has_limit: false,
    ageLimit: null,
    limit_type: 'over',
    age_limit: 'over_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'pediatric_teen',
    recommendedPricingFormula: [
      ['1110', 2],
      ['0120', 2],
      ['0274', 1],
      ['0220', 2],
    ], // (D1110 x 2) + (D0120 x 2) + D0274 + (D0220 x 2)
  },
  {
    name: 'Perio 1x',
    description:
      '1 periodontal maintenance per year\n' +
      'Fluoride treatment with cleaning\n' +
      '1 regular exam per year\n' +
      'Oral cancer screening with cleaning\n' +
      '1 set of routine X-rays per year\n' +
      '1 emergency exam with X-ray per year\n' +
      'Up to __% off additional treatment\n',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: '',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'periodontic_perio1',
    recommendedPricingFormula: [
      ['4910', 1],
      ['0120', 1],
      ['0274', 1],
      ['0220', 2],
    ], //  D4910 + D0120 + D0274 + (D0220 x 2)
  },
  {
    name: 'Perio 2x',
    description:
      '1 periodontal maintenance every 6 months\n' +
      'Fluoride treatment with cleaning\n' +
      '2 regular exams per year\n' +
      'Oral cancer screening with cleaning\n' +
      '1 set of routine X-rays per year\n' +
      '1 emergency exam with X-ray per year\n' +
      'Up to __% off additional treatment\n',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: '',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'periodontic_perio2',
    recommendedPricingFormula: [
      ['4910', 2],
      ['0120', 2],
      ['0274', 1],
      ['0220', 2],
    ], // (D4910 x 2) + (D0120 x 2) + D0274 + (D0220 x 2)
  },
  {
    name: 'Perio 4x',
    description:
      '1 periodontal maintenance every 3 months\n' +
      'Fluoride treatment with cleaning\n' +
      '2 regular exams per year\n' +
      'Oral cancer screening with cleaning\n' +
      '1 set of routine X-rays per year\n' +
      '1 emergency exam with X-ray per year\n' +
      'Up to __% off additional treatment\n',
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: '',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: 'periodontic_perio4',
    recommendedPricingFormula: [
      ['4910', 4],
      ['0120', 2],
      ['0274', 1],
      ['0220', 2],
    ], // (D4910 x 4) + (D0120 x 2) + D0274 + (D0220 x 2)
  },
  {
    name: 'Whitening Plan',
    description:
      '1 basic cleaning every 6 months\nOral cancer screening with cleaning\n1-2 exams/year\nX-rays as determined necessary\n1 emergency exam with X-ray/year\nUp to __% off additional treatment\n1 whitening treatment/year',
    codes: [],
    min_price: 19.99,
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: true,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: null,
  },
  {
    name: 'Retainer Club',
    description: '1 new retainer every 6 months',
    codes: [],
    min_price: 5,
    yearly_price: null,
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: false,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: null,
  },
  {
    name: 'Denture Plan',
    description:
      '1 minor repair or refit/year due to wear and tear\n1 exam/oral cancer screening/year\n1__% off any additional services',
    codes: [],
    price: null,
    min_price: 5,
    yearly_price: null,
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: false,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: null,
  },
  {
    name: 'OverDenture Plan',
    description:
      '1 minor repair or refit/year due to wear and tear\n1 exam/oral cancer screening/year\n1 set of attachment replacements/year\n1__% off any additional services',
    codes: [],
    price: null,
    min_price: 5,
    yearly_price: null,
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: false,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: null,
  },
  {
    name: 'Out Of Network Plan',
    description: 'Teledentistry\nUp to __% off additional treatment',
    codes: [],
    price: null,
    min_price: 5,
    yearly_price: null,
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: false,
    fluoride: false,
    whitening: false,
    teledentistry: true,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: null,
  },
  {
    name: 'VIP Plan',
    description:
      '1 basic cleaning every 6 months\nOral cancer screening with cleaning\n1-2 exams/year\nX-rays as determined necessary\n1 emergency exam with X-ray/year\nUp to __% off additional treatment',
    codes: [],
    min_price: 25,
    yearly_membership: [],
    subscription_age_group: 'adult',
    type: 'custom',
    emergency: true,
    fluoride: false,
    whitening: false,
    teledentistry: false,
    maxAge: null,
    minAge: null,
    has_limit: false,
    ageLimit: null,
    limit_type: 'no_limit',
    age_limit: 'no_limit',
    worryFree: { year: null, month: null },
    showOnEnrollmentMonthly: true,
    showOnEnrollmentAnnual: true,
    showOnBC: true,
    yearly_active: false,
    monthly_active: false,
    presetType: null,
  },
];

export default SeedPlansCustom;
