import { Id } from 'new/api/types';
import {
  claimGuarantee,
  clearDelinquency,
} from 'new/modules/MembersPage/endpoints';
import { useMutation, useQuery } from 'react-query';
import create, { GetState, SetState } from 'zustand';
import { StoreApiWithPersist, persist } from 'zustand/middleware';
import {
  UpdatePlanPricingMultiRequest,
  UpdatePlanPricingMultiResponse,
  UpdatePlanStatusMultiRequest,
} from '../ManagePlans/types';
import {
  changeMembershipSettingsSL,
  changePlanStatus,
  changePlansOrder,
  createMasterPlan,
  createPlan,
  editMasterPlan,
  editPlan,
  editSimplePlan,
  getPlanDetail,
  getPlans,
  recommendedPricing,
  updatePlanPricing,
  updatePlanStatus,
} from './endpoints';
import {
  ChangePlansOrderRequest,
  ChangePlansOrderResponse,
  ChangeStatusRequest,
  CreateMasterPlanRequest,
  CreatePlanRequest,
  CreatePlanResponse,
  EditMasterPlanRequest,
  EditPlanRequest,
  EditPlanResponse,
  EditSLPlanRequest,
  GetPlanDetailRequest,
  MembershipPlansStore,
  MembershipSettingsRequest,
  PlanStorePlan,
} from './types';

export const useMembershipPlansTabsStore = create<
  MembershipPlansStore,
  SetState<MembershipPlansStore>,
  GetState<MembershipPlansStore>,
  StoreApiWithPersist<MembershipPlansStore>
>(
  persist(
    (set) => ({
      membershipPlansTabs: [] as PlanStorePlan[],
      setMembershipPlansTabs: (membershipPlansTabs) =>
        set({ membershipPlansTabs }),
      resetMembershipPlansTabs: () => set({ membershipPlansTabs: [] }),
    }),
    {
      name: 'manageMembershipPlansTabs',
      version: 1,
      getStorage: () => sessionStorage,
    },
  ),
);

export const useGetPlanDetail = (
  dentistInfoId?: number,
  getPlanDetailRequest?: GetPlanDetailRequest,
) =>
  useQuery({
    queryKey: ['MANAGE_PLAN_DETAIL', getPlanDetailRequest, dentistInfoId],
    queryFn: () =>
      getPlanDetail(dentistInfoId ? dentistInfoId : 0)
        .endpoint(getPlanDetailRequest, '')
        .then((res) => res.data),
    enabled: !!dentistInfoId && !!getPlanDetailRequest?.planId,
  });

export const useGetPlans = (dentistInfoId?: Id) =>
  useQuery({
    queryKey: `Plans: ${dentistInfoId}`,
    queryFn: () => getPlans.endpoint({}, dentistInfoId).then((res) => res.data),
    enabled: !!dentistInfoId,
  });

export const useChangePlansOrder = () =>
  useMutation<ChangePlansOrderResponse, unknown, ChangePlansOrderRequest>(
    ({ dentistInfoId, ...params }) =>
      changePlansOrder.endpoint(params, dentistInfoId).then((res) => res.data),
  );

export const useChangePlanStatus = () =>
  useMutation<unknown, unknown, ChangeStatusRequest>((params) =>
    changePlanStatus.endpoint(params).then((res) => res.data),
  );

export const useCreatePlan = () =>
  useMutation<
    CreatePlanResponse,
    unknown,
    CreatePlanRequest & { dentistOfficeId: number }
  >(({ dentistOfficeId, ...params }) =>
    createPlan.endpoint(params, dentistOfficeId).then((res) => res.data),
  );

export const useCreateMasterPlan = () =>
  useMutation<
    CreatePlanResponse,
    unknown,
    CreateMasterPlanRequest & { organizationId: number }
  >(({ organizationId, ...params }) =>
    createMasterPlan.endpoint(params, organizationId).then((res) => res.data),
  );

export const useChangeMembershipSettingsSL = () =>
  useMutation<unknown, unknown, MembershipSettingsRequest>((params) =>
    changeMembershipSettingsSL.endpoint(params).then((res) => res.data),
  );

export const useEditPlan = () =>
  useMutation<EditPlanResponse, unknown, EditSLPlanRequest>((params) =>
    editPlan.endpoint(params).then((res) => res.data),
  );

export const useEditMasterPlan = () =>
  useMutation<EditPlanResponse, unknown, EditMasterPlanRequest>((params) =>
    editMasterPlan.endpoint(params).then((res) => res.data),
  );

export const useEditSimplePlan = () =>
  useMutation<
    EditPlanResponse,
    unknown,
    EditPlanRequest & { dentistOfficeId: number }
  >(({ dentistOfficeId, ...params }) =>
    editSimplePlan.endpoint(params, dentistOfficeId).then((res) => res.data),
  );

export const useUpdatePlanStatus = () =>
  useMutation<unknown, unknown, UpdatePlanStatusMultiRequest>((params) =>
    updatePlanStatus.endpoint(params).then((res) => res.data),
  );

export const useUpdatePlanPricing = (dentistInfoId: number) =>
  useMutation<
    UpdatePlanPricingMultiResponse,
    unknown,
    UpdatePlanPricingMultiRequest
  >((params) =>
    updatePlanPricing(dentistInfoId)
      .endpoint(params)
      .then((res) => res.data),
  );

export const useHandleRecommendedPricing = (dentistInfoId: string) =>
  useQuery({
    queryKey: `RECOMMENDED_PRICING ${dentistInfoId}`,
    queryFn: () => recommendedPricing.endpoint().then((res) => res.data),
  });

export const useClearDelinquency = () => useMutation(clearDelinquency.endpoint);

export const useClaimGuarantee = () => useMutation(claimGuarantee.endpoint);
