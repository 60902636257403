import { NamedRoute } from '../../routing/types';

export type MembershipPlansManageLegacyRoutesName = 'MembershipPlansManageLegacy';

export const membershipPlansManageLegacyRoutes: NamedRoute[] = [
  {
    name: 'MembershipPlansManageLegacy',
    path: '/account/settings/membership-plan-legacy',
    permission: '_all',
  },
];
