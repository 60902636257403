/*
NavBar Component
================================================================================
NOTE: Add custom left-hand-side elements for routes in the `returnLinks`
      dictionary (a class field).
*/

/*
Imports
------------------------------------------------------------
*/
// libs
import React from 'react';
import PropTypes from 'prop-types';
// import Image from 'react-bootstrap/lib/Image';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { push } from 'react-router-redux';
import FaFile from 'react-icons/lib/fa/file';
import FaUserMd from 'react-icons/lib/fa/user-md';
import FaWrench from 'react-icons/lib/fa/wrench';
import FaUserSecret from 'react-icons/lib/fa/user-secret';
import FaUserPlus from 'react-icons/lib/fa/user-plus';
import FaList from 'react-icons/lib/fa/list';
import FaDollar from 'react-icons/lib/fa/dollar';
import FaBarChart from 'react-icons/lib/fa/bar-chart';
import FaBuilding from 'react-icons/lib/fa/building';
import PaymentPlanModal from 'components/PaymentPlanModal';
import AddLocationModal from 'components/AddLocationModal';
import { toast } from 'react-toastify';
import moment from 'moment';

import StatsModal from 'components/StatsModal';

import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from 'react-accessible-accordion';

import InviteModal from 'components/InviteModal';
import ShowStripeModal from 'components/ShowStripeModal';
import { getItem, removeItem } from 'utils/localStorage';

// app
import dentalhqLogo from 'new/assets/DHQ-logo_RGB_fullcolor-ondark.png';
import { selectCurrentUser } from 'containers/App/selectors';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

// local
import styles from './styles.module.css';

import {
  // fetch
  fetchStats,

  
} from 'containers/AdminDentistsPage/actions';
import {
  // fetch

  selectStats,

} from 'containers/AdminDentistsPage/selectors';

/*
Redux
------------------------------------------------------------
*/
function mapStateToProps (state) {
  return {
    loggedInUser: selectCurrentUser(state),
    user: selectCurrentUser(state),
    stats: selectStats(state)
  };
}

function mapDispatchToProps (dispatch) {
  return {
    changeRoute: (url) => dispatch(push(url)),
    fetchStats: () => dispatch(fetchStats()),
  }
}


/*
NavBar
================================================================================
*/
@connect(mapStateToProps, mapDispatchToProps)
@CSSModules(styles, { allowMultiple: true })
export default class SideNavAdmin extends React.Component {

  static propTypes = {
    changeRoute: PropTypes.func,
    loggedInUser: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ]),
    pathname: PropTypes.string,
    user: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.object,
    ]),
    stats: PropTypes.object,
    fetchStats: PropTypes.func.isRequired,
  }

  constructor (props) {
    super(props);

    this.state = {
      showStats: false,
    };
  }

  UNSAFE_componentWillMount () {
  }


  selectTab = (key) => {
    switch (key) {
      case 'edit-profile':
        this.props.changeRoute('/associations/edit-profile');
        break;
      case 'reports':
        this.props.changeRoute('/associations/reporting');
        break;
      case 'dentists':
        this.props.changeRoute('/associations/dentists');
        break;
     
      default:
        // Don't do anything... we should never actually reach here.
        break;
    }
  }

  toggleStats=()=>{
    this.setState({showStats:!this.state.showStats});
  }

  /*
  Component Actions
  ------------------------------------------------------------
  */

  render () {
      const { firstName, lastName, avatar, email,type} = this.props.loggedInUser;
      const jwt = getItem('auth_token');


    // else - show no logo

      return (
        <div styleName="sidebar_admin">
          <Accordion>
            <AccordionItem onClick={() =>this.selectTab('dentists')}  >
              <AccordionItemTitle styleName={this.props.pathname.indexOf('admin/dentists')!=-1?"on_dashboard":""}>
              <FaUserMd/>&emsp;Dentists
              </AccordionItemTitle>
            </AccordionItem>
          
            <AccordionItem  onClick={() =>this.selectTab('reports')}>
                <AccordionItemTitle >
                    <FaFile/>&emsp;Reports
                </AccordionItemTitle>
            </AccordionItem>
      </Accordion>
     </div>
      );
  }
}
