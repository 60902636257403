import {
  blue,
  blueGrey,
  cyan,
  deepOrange,
  green,
  lightBlue,
  lightGreen,
  lime,
  orange,
  purple,
  red,
  teal,
  yellow,
} from '@mui/material/colors';

export const colorsArr = [
  blue[600],
  green[500],
  orange[500],
  purple[400],
  red[800],
  lightGreen[600],
  red[400],
  cyan[500],
  lightBlue[400],
  blueGrey[600],
  lime[400],
  yellow[500],
  teal[400],
  deepOrange[400],
];
