import { DragDropItem } from 'new/components/DragDrop/types';
import {
  AnalyticDashboardChartsRequest,
  WidgetId,
} from 'new/modules/AnalyticDashboardPage/types';
import { colorsArr } from 'new/constants/colors';
import moment from 'moment';

export const DASHBOARD_REFETCH_INTERVAL = 100000;

export const widgets = {
  // revenueLineData: 'Revenue',
  memberVsNonMemberData: 'Revenue: Members vs. Non-Members',
  activeMembersLineData: 'Active Members',
  revenuePieData: 'Per Plan: Revenue',
  perPlanMemberCountData: 'Per Plan: Member Count',
  addedMembersData: 'Members Added per month',
  // attritionRateData: 'Attrition Rate',
  activityFeed: 'Activity Feed',
  averageRevenue: 'Average Revenue: Member vs Non-Member',
  upcomingAppointments: 'Upcoming Appointments: Uninsured Non-Members',
  multiLocation: 'Multi-location',
  powerUp: 'Power Up',
  localHero: 'Local Business Hero',
  launchRewards: 'Launch Rewards',
  launchChecklist: 'Launch Checklist',
  seasonOfGiving: 'Season of Giving',
  pmsAnnouncement: 'PMS Announcement',
};

export const fixedWidgets = [
  'localHero',
  'powerUp',
  'multiLocation',
  'launchRewards',
  'launchChecklist',
  'seasonOfGiving',
  'activityFeed',
  'pmsAnnouncement',
];

export const DASHBOARD_CONFIG: DragDropItem[] = [
  {
    id: 'multiLocation',
    type: 'advert',
  },
  {
    id: 'activeMembersLineData',
    type: 'line',
    title: widgets.activeMembersLineData,
    subtitle: 'All members',
    data: {
      datasets: [
        {
          borderColor: '#00A1DF',
          backgroundColor: 'transparent',
          pointRadius: 7,
          pointBackgroundColor: '#00A1DF',
        },
        {
          borderColor: '#015879',
          backgroundColor: 'transparent',
          pointRadius: 7,
          pointBackgroundColor: '#015879',
        },
      ],
    },
  },
  /*  {
    id: 'revenueLineData',
    type: 'line',
    title: widgets.revenueLineData,
    subtitle: 'All members',
    data: {
      datasets: [
        {
          borderColor: '#015879',
          backgroundColor: 'transparent',
          pointRadius: 7,
          pointBackgroundColor: '#015879',
        },
        {
          borderColor: '#00A1DF',
          backgroundColor: 'transparent',
          pointRadius: 7,
          pointBackgroundColor: '#00A1DF',
        },
      ],
    },
  },*/
  {
    id: 'averageRevenue',
    type: 'sizedText',
    primaryTitle: 'Member',
    secondaryTitle: 'Uninsured Non-member',
    period: 'yr',
  },
  {
    id: 'powerUp',
    type: 'advert',
  },
  {
    id: 'addedMembersData',
    type: 'bar',
    title: widgets.addedMembersData,
    subtitle: 'All members',
    data: {
      datasets: [
        {
          backgroundColor: '#00A1DF',
          label: 'Monthly',
        },
        {
          backgroundColor: '#015879',
          label: 'Annual',
        },
      ],
    },
  },
  {
    id: 'revenuePieData',
    type: 'pie',
    title: widgets.revenuePieData,
    subtitle: 'All members',
    data: {
      datasets: [
        {
          backgroundColor: [
            '#005475',
            '#4ac0e9',
            '#5a60da',
            '#4dc481',
            '#688d9b',
            ...colorsArr,
          ],
        },
      ],
    },
  },
  /*{
    id: 'attritionRateData',
    type: 'bar',
    title: widgets.attritionRateData,
    data: {
      datasets: [
        {
          backgroundColor: '#00A1DF',
          label: 'Members',
        },
        {
          backgroundColor: '#015879',
          label: 'Uninsured Non-Members',
        },
      ],
    },
  },*/

  {
    id: 'localHero',
    type: 'advert',
  },
  {
    id: 'activityFeed',
    type: 'activityFeed',
  },
  {
    id: 'upcomingAppointments',
    type: 'upcomingAppointments',
  },
  {
    id: 'memberVsNonMemberData',
    type: 'line',
    title: widgets.memberVsNonMemberData,
    subtitle: 'Last six months',
    data: {
      datasets: [
        {
          borderColor: '#00A1DF',
          label: 'Members',
          fill: false,
        },
        {
          borderColor: '#015879',
          label: 'Uninsured Non-Members',
          fill: false,
        },
      ],
    },
  },
  {
    id: 'perPlanMemberCountData',
    type: 'pie',
    title: widgets.perPlanMemberCountData,
    subtitle: 'All members',
    data: {
      datasets: [
        {
          backgroundColor: [
            '#005475',
            '#4ac0e9',
            '#5a60da',
            '#4dc481',
            '#688d9b',
            ...colorsArr,
          ],
        },
      ],
    },
  },
  {
    id: 'launchRewards',
    type: 'reward',
  },
  {
    id: 'launchChecklist',
    type: 'reward',
  },
  {
    id: 'seasonOfGiving',
    type: 'custom',
  },
  {
    id: 'pmsAnnouncement',
    type: 'custom',
  },
];

export const DEFAULT_CHART_PARAMS: AnalyticDashboardChartsRequest = {
  activeMembersLineData: {
    show: 'allMembers',
    timeFrame: '6mos',
  },
  revenueLineData: {
    show: 'monthly',
    timeFrame: '6mos',
  },
  // attritionRateData: {
  //   show: 'monthly',
  //   timeFrame: '6mos',
  // },
  addedMembersData: {
    show: 'compare',
    timeFrame: '6mos',
  },
  perPlanMemberCountData: {
    show: 'allMembers',
    timeFrame: '6mos',
  },
  revenuePieData: {
    show: 'allMembers',
    timeFrame: '6mos',
  },
  memberVsNonMemberData: {
    timeFrame: '6mos',
  },
};

export const widgetsIds = Object.keys(widgets) as Array<keyof typeof widgets>;

export const REVENUE_CHART_IDS: WidgetId[] = [
  // 'revenueLineData',
  'revenuePieData',
  'memberVsNonMemberData',
  'averageRevenue',
];

export const LAUNCH_CHECKLIST_ITEMS: Record<number, string> = {
  1: 'Finalize and activate your plans\n& pricing',
  2: 'Order Pricing Cards\n(first order is FREE!)',
  3: 'Watch <a href="https://dentalhq-uploads.s3.amazonaws.com/DHQ-TT.mp4" target="_blank">Team Training Video</a>',
  4: 'Additional questions? Email\n<a href="mailto:success@dentalhq.com" target="_blank">success@dentalhq.com</a>',
  5: 'Print and display QR Code signs',
  6: 'Send email to all patients to promote your Membership Program',
  7: 'Create call-to-action on your website linking to the Patient Sign-Up Page',
  8: 'Schedule first Monthly and Quarterly progress calls',
};

export const LAUNCH_REWARDS: Record<
  30 | 60 | 90,
  { prize: number; target: number; targetPeriod: 30 | 60 | 90 }
> = {
  30: {
    prize: 25,
    target: 10,
    targetPeriod: 30,
  },
  60: {
    prize: 50,
    target: 30,
    targetPeriod: 60,
  },
  90: {
    prize: 100,
    target: 60,
    targetPeriod: 90,
  },
};

export const DAYS_TO_SHOW_REWARDS = 95;

export const SEASON_OF_GIVING_LAST_DAY = moment('2024-06-30');
