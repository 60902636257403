import { api } from 'new/api/axios';
import { createEndpoint } from 'new/api/utils';
import { MultiReportParams } from 'new/modules/MultiLocationReports/types';
import {
  CSV_ACCEPT_HEADER,
  PDF_ACCEPT_HEADER,
  XLSX_ACCEPT_HEADER,
} from 'new/modules/Reports/constants';
import {
  DownloadPayoutReportParams,
  LedgerRequest,
  MemberTransactionsResponse,
  PayoutReportsResponse,
} from 'new/modules/Reports/types';

export const getPayoutReports = createEndpoint<PayoutReportsResponse, unknown>({
  url: '/DBreports/dentist/payouts/:id/list',
  endpoint: ({ url }) => api.get(url),
});

export const getLedgerEndpoint = ({
  officeId,
  startDate,
  endDate,
}: LedgerRequest) => {
  const endpointUrl = `/dentists/${officeId}/finances/byDate/${startDate}/${endDate}`;

  return api.post<MemberTransactionsResponse>(endpointUrl);
};

export const filetypeHeaderMap: Record<
  DownloadPayoutReportParams['fileType'],
  string
> = {
  csv: CSV_ACCEPT_HEADER,
  xlsx: XLSX_ACCEPT_HEADER,
  pdf: PDF_ACCEPT_HEADER,
};

const filetypeUrlMap = {
  csv: 'csv=true&download=true',
  xlsx: 'xlsx=true&download=true',
  pdf: 'download=true',
};

export const downloadPayoutReport = ({
  dentistInfoId,
  id,
  fileName,
  fileType,
  upgraded,
}: DownloadPayoutReportParams) => {
  const url = `/DBreports/dentist/${dentistInfoId}/${
    upgraded ? 'upgrade_payout' : 'payout'
  }/${id}?${filetypeUrlMap[fileType]}`;

  return api
    .get<never>(url, {
      headers: {
        accept: filetypeHeaderMap[fileType],
      },
      responseType: 'blob',
    })
    .then((response) => {
      const downloadUrl = window.URL.createObjectURL(
        new Blob([response.data], {
          type: filetypeHeaderMap[fileType],
        }),
      );
      const link = document.createElement('a');

      if (fileType === 'pdf') {
        window.open(downloadUrl, '_blank');
      } else {
        link.href = downloadUrl;
        link.setAttribute('download', `${fileName}.${fileType}`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(downloadUrl);
      }
      return response;
    });
};

export const downloadMultiLocationReport = ({
  fileType,
  reportType,
  ...bodyParams
}: MultiReportParams) => {
  const url = `/multi-reports/${reportType}?${filetypeUrlMap[fileType]}`;

  return api
    .post<never>(
      url,
      { ...bodyParams, fileType },
      {
        headers: {
          accept: filetypeHeaderMap[fileType],
        },
        responseType: 'blob',
      },
    )
    .then((response) => {
      const downloadUrl = window.URL.createObjectURL(
        new Blob([response.data], {
          type: filetypeHeaderMap[fileType],
        }),
      );
      const link = document.createElement('a');

      if (fileType === 'pdf') {
        window.open(downloadUrl, '_blank');
      } else {
        link.href = downloadUrl;
        link.setAttribute('download', `${reportType}.${fileType}`);
        document.body.appendChild(link);
        link.click();
        window.URL.revokeObjectURL(downloadUrl);
      }
      return response;
    });
};
