import { api } from 'new/api/axios';
import { createEndpoint } from 'new/api/utils';
import {
  ManagePlanDetailSL,
  UpdatePlanPricingMultiRequest,
  UpdatePlanPricingMultiResponse,
  UpdatePlanStatusMultiRequest,
} from '../ManagePlans/types';
import {
  ChangePlansOrderRequest,
  ChangePlansOrderResponse,
  ChangeStatusRequest,
  CreateMasterPlanRequest,
  CreatePlanRequest,
  CreatePlanResponse,
  EditMasterPlanRequest,
  EditPlanRequest,
  EditPlanResponse,
  EditSLPlanRequest,
  GetPlanDetailRequest,
  MembershipSettingsRequest,
  PlansRequestResponse,
  RecommendedPriceResponse,
} from './types';

export const getPlans = createEndpoint<PlansRequestResponse>({
  url: '/users/:id/dentist-info/all-plans/upgrade',
  endpoint: ({ url }) => api.post(url),
});

export const getPlanDetail = (dentistInfoId: number) =>
  createEndpoint<ManagePlanDetailSL, GetPlanDetailRequest>({
    url: `/users/${dentistInfoId}/dentist-info/membershipPlanDetail`,
    endpoint: ({ url, params }) => api.post<ManagePlanDetailSL>(url, params),
  });

export const changePlansOrder = createEndpoint<
  ChangePlansOrderResponse,
  Omit<ChangePlansOrderRequest, 'dentistInfoId'>
>({
  url: '/dentists/:id/memberships/sort',
  endpoint: ({ url, params }) =>
    api.post<ChangePlansOrderResponse>(url, params),
});

export const changePlanStatus = createEndpoint<
  ChangePlansOrderResponse,
  ChangeStatusRequest
>({
  url: '/dentists/me/custom-memberships',
  endpoint: ({ url, params }) => api.delete(url, { data: params }),
});

export const createPlan = createEndpoint<CreatePlanResponse, CreatePlanRequest>(
  {
    url: '/dentists/:id/custom-memberships/simple',
    endpoint: ({ url, params }) => api.post(url, params),
  },
);

export const createMasterPlan = createEndpoint<
  CreatePlanResponse,
  CreateMasterPlanRequest
>({
  url: '/multi-location/create-master-plan/:id',
  endpoint: ({ url, params }) => api.post(url, params),
});

export const changeMembershipSettingsSL =
  createEndpoint<MembershipSettingsRequest>({
    url: '/dentists/updateMembershipSettings',
    endpoint: ({ url, params }) =>
      api.post<MembershipSettingsRequest>(url, params),
  });

export const editPlan = createEndpoint<EditPlanResponse, EditSLPlanRequest>({
  url: '/dentists/me/custom-memberships',
  endpoint: ({ url, params }) => api.patch(url, params),
});

export const editMasterPlan = createEndpoint<
  EditPlanResponse,
  EditMasterPlanRequest
>({
  url: '/multi-location/update-master-plan',
  endpoint: ({ url, params }) => api.patch(url, params),
});

export const editSimplePlan = createEndpoint<EditPlanResponse, EditPlanRequest>(
  {
    url: '/dentists/:id/custom-memberships/simple',
    endpoint: ({ url, params }) => api.patch(url, params),
  },
);

export const recommendedPricing = createEndpoint<RecommendedPriceResponse>({
  url: '/pricing',
  endpoint: ({ url }) => api.get(url),
});

export const updatePlanStatus = createEndpoint<UpdatePlanStatusMultiRequest>({
  url: '/dentist-info/membership-plan-save-state',
  endpoint: ({ url, params }) =>
    api.post<UpdatePlanStatusMultiRequest>(url, params),
});

export const updatePlanPricing = (dentistInfoId: number) =>
  createEndpoint<UpdatePlanPricingMultiResponse, UpdatePlanPricingMultiRequest>(
    {
      url: `/dentists/${dentistInfoId}/custom-memberships/changePlanPrice`,
      endpoint: ({ url, params }) => api.post(url, params),
    },
  );
