import { useMediaQuery, useTheme } from '@mui/material';
import { AxiosError, AxiosResponse } from 'axios';
import { selectCurrentUser } from 'containers/App/selectors';
import { Id } from 'new/api/types';
import useActiveOffice from 'new/hooks/useActiveOffice';
import { initialNoteFormValues } from 'new/modules/MembersPage/constants';
import {
  addCard,
  addMembers,
  addNote,
  autoRenew,
  cancelMember,
  cancelMemberInOffice,
  cancelPendingPlan,
  cashPaymentSubscribe,
  changeDrawDate,
  changePlan,
  chargeMember,
  convertMember,
  editMemberInfo,
  emailUs,
  getAllPlans,
  getHistory,
  getLedgerEndpoint,
  getMemberDetail,
  inOfficePayment,
  invitePatient,
  pastDue,
  refundMember,
  removeChartId,
  renewPlan,
  retryPayment,
  searchMembersData,
  sendSecurePaymentUpdateLink,
  setChartId,
  switchToInOffice,
} from 'new/modules/MembersPage/endpoints';
import {
  AddCardRequest,
  AddCardResponse,
  AddMemberRequest,
  CancelationInOfficeRequest,
  CancelationRequest,
  ChangePlanRequest,
  ConvertMemberRequest,
  EditMemberInfoFormValues,
  EmailUsFormValues,
  MemberDetailRequest,
  MemberDetailResponse,
  MemberTabType,
  MemberTabsStore,
  MembersDataRequest,
  MembersDataResponse,
  NoteFormValues,
  NoteStoreValues,
  RefundMemberRequest,
  RefundMemberResponse,
  RenewPlanRequest,
  ScrollPositionStore,
  SendSecurePaymentUpdateLinkRequest,
} from 'new/modules/MembersPage/types';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import create, { GetState, SetState } from 'zustand';
import { StoreApiWithPersist, persist } from 'zustand/middleware';
import { EnrollUser } from '../EnrollPage/types';
import { InvitePatientFormValues } from './components/InvitePatientModal';

const defaultSearchParams: Partial<MembersDataRequest> = {
  startingAt: 0,
};

export const useLazySearchMembersData = (
  officeId: string,
  params: MembersDataRequest,
  enabled = true,
) => {
  return useInfiniteQuery<MembersDataResponse, MembersDataRequest>({
    queryKey: ['MEMBERS_DATA', officeId, params],
    queryFn: ({ pageParam }) =>
      searchMembersData
        .endpoint(
          {
            ...defaultSearchParams,
            ...params,
            startingAt: typeof pageParam === 'number' ? pageParam : 0,
            searchQuery: params?.searchQuery?.replace("'", '&#39;') ?? null,
          },
          officeId,
        )
        .then((res) => res.data),
    enabled: enabled && !!officeId,
    getNextPageParam: (lastPage) => !lastPage.noMoreResults,
    keepPreviousData: true,
  });
};

export const useSearchMembers = (officeId: string) =>
  useMutation<MembersDataResponse, unknown, { searchQuery: string }>((params) =>
    searchMembersData
      .endpoint(
        {
          ...defaultSearchParams,
          searchFilter: 'all',
          searchQuery: params?.searchQuery?.replace("'", '&#39;') ?? null,
          initial: false,
        },
        officeId,
      )
      .then((res) => res.data),
  );

export const useGetMemberDetail = (
  officeId: string,
  params: MemberDetailRequest,
) => {
  return useQuery<MemberDetailResponse, MemberDetailRequest>({
    queryKey: ['MEMBERS_DETAIL', officeId, params],
    queryFn: () =>
      getMemberDetail.endpoint(params, officeId).then((res) => res.data),
    enabled: !!officeId && !!params.memberId,
  });
};

export const useInvitePatient = () =>
  useMutation<unknown, unknown, InvitePatientFormValues>((params) =>
    invitePatient.endpoint(params),
  );

export const useEditMemberSubmit = (memberId?: Id) =>
  useMutation<unknown, unknown, EditMemberInfoFormValues>((params) =>
    editMemberInfo.endpoint(params, memberId),
  );

export const useEmailUsSubmit = () =>
  useMutation<unknown, unknown, EmailUsFormValues>((params) =>
    emailUs.endpoint(params),
  );

export const useMemberTabsStore = create<
  MemberTabsStore,
  SetState<MemberTabsStore>,
  GetState<MemberTabsStore>,
  StoreApiWithPersist<MemberTabsStore>
>(
  persist(
    (set) => ({
      memberTabs: [] as MemberTabType[],
      setMemberTabs: (memberTabs) => set({ memberTabs }),
      resetMemberTabs: () => set({ memberTabs: [] }),
    }),
    { name: 'memberTabs', version: 1, getStorage: () => sessionStorage },
  ),
);

export const useAutoRenew = () => useMutation(autoRenew.endpoint);

export const useRenewPlan = () =>
  useMutation(
    ({ memberId, ...params }: RenewPlanRequest & { memberId: number }) =>
      renewPlan.endpoint(params, memberId),
  );

// export const useCancelation = () => {
//   const { activeOfficeId } = useActiveOffice();
//   return useMutation(cancelMember(activeOfficeId).endpoint);
// }

export const useCancelation = () => {
  const { activeOfficeId } = useActiveOffice();
  return useMutation<
    unknown,
    AxiosError<{ error?: string; statusCode: number }>,
    CancelationRequest
  >((params) => cancelMember.endpoint(params, activeOfficeId));
};

export const useCancelationInOffice = () => {
  const { activeOfficeId } = useActiveOffice();
  return useMutation<unknown, unknown, CancelationInOfficeRequest>((params) =>
    cancelMemberInOffice.endpoint(params, activeOfficeId),
  );
};

export const useNoteStore = create<NoteStoreValues>((set) => ({
  noteValues: {},
  setNoteValues: (noteValues: NoteFormValues, id: number) =>
    set((state) => ({
      noteValues: {
        ...state.noteValues,
        [id]: state.noteValues[id] ? noteValues : initialNoteFormValues,
      },
    })),
}));

export const useGetHistory = (patientId: Id) =>
  useQuery({
    queryKey: ['MEMBER_HISTORY', patientId],
    queryFn: () => getHistory.endpoint({}, patientId).then((res) => res.data),
  });

export const useGetLedger = (userId?: Id, isSingleAccount?: boolean) => {
  const year = new Date().getFullYear();

  return useQuery({
    queryKey: ['LEDGER', String(userId), year, isSingleAccount],
    queryFn: () =>
      getLedgerEndpoint({ userId, year, isSingleAccount }).then(
        (res) => res.data,
      ),
    enabled: !!userId,
  });
};

export const useGetAllPlans = (dentistInfoId?: Id) =>
  useQuery({
    queryKey: ['ALL_PLANS', dentistInfoId],
    queryFn: () =>
      getAllPlans.endpoint({}, dentistInfoId).then((res) => res.data),
    enabled: !!dentistInfoId,
  });

export const useAddNote = () => useMutation(addNote.endpoint);

export const useChangePlan = () =>
  useMutation<
    unknown,
    AxiosError<{ _message?: string; statusCode: number }>,
    ChangePlanRequest
  >(changePlan.endpoint);

export const useAddCard = () =>
  useMutation<AxiosResponse<AddCardResponse>, AxiosError, AddCardRequest>(
    ({ id, ...params }) => addCard.endpoint(params, id),
  );

export const useMemberScrollPosition = create<ScrollPositionStore>((set) => ({
  scrollPosition: 0,
  setScrollPosition: (scrollPosition) => set({ scrollPosition }),
}));

export const useCashPaymentSubscribe = (initMemberId?: Id) =>
  useMutation<unknown, AxiosError, { memberId?: Id }>(({ memberId }) =>
    cashPaymentSubscribe.endpoint({}, initMemberId || memberId),
  );

export const usePastDue = (memberId?: string) =>
  useMutation<unknown, AxiosError, unknown>(() =>
    pastDue.endpoint({}, memberId),
  );

export const useChargeMember = () => useMutation(chargeMember.endpoint);

export const useRefundMember = () =>
  useMutation<
    AxiosResponse<RefundMemberResponse>,
    AxiosError<{ errors: string }>,
    RefundMemberRequest
  >(refundMember.endpoint, {
    onSuccess: (res) => {
      toast.success(res.data.message);
    },
  });

export const useAddMembers = () =>
  useMutation<unknown, unknown, AddMemberRequest>((params) =>
    addMembers.endpoint(params, params.userId),
  );

export const useCancelPendingPlan = () =>
  useMutation(cancelPendingPlan.endpoint);

export const useRetryPayment = () => useMutation(retryPayment.endpoint);

export const useConvertMember = () =>
  useMutation<unknown, unknown, ConvertMemberRequest>(convertMember.endpoint);

export const useSetChartId = () => useMutation(setChartId.endpoint);

export const useRemoveChartId = () => useMutation(removeChartId.endpoint);

export const useMediaQueryVars = () => {
  const theme = useTheme();
  const isUnder1600 = useMediaQuery(theme.breakpoints.down(1601));
  const isUnder1480 = useMediaQuery(theme.breakpoints.down(1481));
  const isUnder1280 = useMediaQuery(theme.breakpoints.down(1281));

  return {
    dateColumnWidth: isUnder1600 ? 80 : 100,
    activityFeedWidth: isUnder1480 ? 365 : 470,
    maWidth: isUnder1600 ? 70 : 100,
    isUnder1600,
    isUnder1480,
    isUnder1280,
  };
};

export const useUserNameForVerification = (): string => {
  const user: EnrollUser = useSelector(selectCurrentUser);
  const username =
    user.firstName && user.lastName
      ? user.firstName + ' ' + user.lastName
      : user.firstName
      ? user.firstName
      : user.lastName
      ? user.lastName
      : '';
  return username;
};

export const useInOfficePayment = () => useMutation(inOfficePayment.endpoint);

export const useChangeDrawDate = () => useMutation(changeDrawDate.endpoint);

export const useSwitchToInOffice = () => useMutation(switchToInOffice.endpoint);

export const useSendSecurePaymentUpdateLink = () => {
  return useMutation<
    unknown,
    unknown,
    SendSecurePaymentUpdateLinkRequest & { id: string }
  >((params) => {
    const { id, ...body } = params;
    return sendSecurePaymentUpdateLink.endpoint(body, id);
  });
};
