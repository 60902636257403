/* Admin Page Actions
 * ========================================================================== */
// local
import {
  // fetch dentists
  FETCH_DENTISTS_REQUEST,
  FETCH_DENTISTS_SUCCESS,
  FETCH_DENTISTS_SUCCESS_ALL,
  FETCH_DENTISTS_ERROR,
  FETCH_MEMBERS_REQUEST,
  FETCH_MEMBERS_SUCCESS,
  FETCH_MEMBERS_SUCCESS_ALL,
  FETCH_MEMBERS_ERROR,
  FETCH_DENTISTS_REPORTS_REQUEST,

  FETCH_DENTIST_LIST_REQUEST,
  FETCH_DENTIST_LIST_SUCCESS,

  // fetch dentist details
  FETCH_DENTIST_DETAILS_REQUEST,
  FETCH_DENTIST_DETAILS_SUCCESS,
  FETCH_DENTIST_DETAILS_ERROR,

  // fetch dentist members
  FETCH_DENTIST_MEMBERS_REQUEST,
  FETCH_SINGLE_MEMBER_REQUEST,
  FETCH_DENTIST_MEMBERS_SUCCESS,
  FETCH_DENTIST_MEMBERS_ERROR,

  // fetch dentist reports
  FETCH_DENTIST_REPORTS_REQUEST,
  FETCH_DENTIST_REPORTS_SUCCESS,
  FETCH_DENTIST_REPORTS_ERROR,

  // fetch dentist reports
  FETCH_MANAGER_REPORTS_REQUEST,
  FETCH_MANAGER_REPORTS_SUCCESS,
  FETCH_MANAGER_REPORTS_ERROR,

  // fetch dentist reviews
  FETCH_DENTIST_REVIEWS_REQUEST,
  FETCH_DENTIST_REVIEWS_SUCCESS,
  FETCH_DENTIST_REVIEWS_ERROR,

  // fetch dentist stats
  FETCH_STATS_REQUEST,
  FETCH_STATS_SUCCESS,
  FETCH_STATS_ERROR,

  // set selected dentist
  SET_SELECTED_DENTIST,
  SET_SELECTED_MEMBER,
  SET_SELECTED_MANAGER,

  // search / sort patients
  SEARCH,
  SORT,

  // edit dentist
  EDIT_DENTIST_REQUEST,
  EDIT_DENTIST_SUCCESS,
  EDIT_DENTIST_ERROR,
  SET_EDITING_DENTIST_ID,
  REDIRECT_TO_ADMIN,

  // download report
  DOWNLOAD_REPORT_REQUEST,
  DOWNLOAD_REPORT_SUCCESS,
  DOWNLOAD_REPORT_ERROR,

  // download master report
  DOWNLOAD_MASTER_REPORT_REQUEST,
  DOWNLOAD_MASTER_REPORT_SUCCESS,
  DOWNLOAD_MASTER_REPORT_ERROR,

  // delete review
  DELETE_DENTIST_REVIEW_REQUEST,
  DELETE_DENTIST_REVIEW_SUCCESS,
  DELETE_DENTIST_REVIEW_ERROR,

  //managers
  FETCH_MANAGERS_REQUEST,
  FETCH_SUPPORTS_REQUEST,
  TOGGLE_REFUNDING_MEMBER,
  INITIATE_REFUNDING_MEMBER,

  TOGGLE_CHARGING_MEMBER,
  INITIATE_CHARGING_MEMBER,
  TOGGLE_DRAWDATE_MEMBER,
  TOGGLE_BACKDATE_MEMBER,
  TOGGLE_ENROLL_NO_CHARGE,
  INITIATE_ENROLL_NO_CHARGE,
  INITIATE_DRAWDATE_MEMBER,
  INITIATE_BACKDATE_MEMBER,
  FETCH_MASTER_REPORTS_DATES,
  TOGGLE_CHANGE_PLANS,
  INITIATE_CHANGE_PLANS,
  TOGGLE_TRANSFERRING_MEMBER,
  TRANSFER_MEMBER,
  FETCH_MEMBERSHIPS_SUCCESS,

  // terms and conditions update
  TOGGLE_TERMS_UPDATE,
  TERMS_UPDATE_REQUEST,

  // security form
  TOGGLE_SECURITY_FORM,
  SECURITY_FORM_SUBMIT_REQUEST,
  UPDATE_GEOLOCATION,
  UNDO_STANDARD,
  REMOVE_BAA,
  UPDATE_AFFORDABILITY,
  UPDATE_ONBOARDING,
  UPDATE_NINETYDAY,
  UPDATE_MARKETING_MATERIALS_PAID,
  UPDATE_BOX_SHIPPED,
  UPDATE_MEMBERSHIP_TRAINING,
  UPDATE_BUSINESS_CONNECT_ON,
  UPDATE_BUSINESS_CONNECT_TRAINING_ON,
  TOGGLE_MARKETING_MATERIALS_CHARGE,

  ADMIN_LOGIN_REQUEST,
  ADMIN_LOGIN_SPECIFIC_USER,
  ADMIN_LOGIN_DIRECTLY_TO_MEMBER,
  MEMBER_LOGIN_REQUEST,
  REFRESH_DENTISTS,
  DELETE_INCOMPLETE_OFFICE,
  DELETE_INCOMPLETE_SUCCESS,

  FETCH_BOX_REPORTS,
  SET_COMPLETE_OFFICE,
  SET_COMPLETE_SUCCESS,
  TOGGLE_TO_CASH_MEMBER,
  INITIATE_TO_CASH_MEMBER,
  IMPORT_OFFICE_TRANSACTIONS_REQUEST,
  TOGGLE_DELETION_REQUEST,
  MOVE_MEMBER_TO_OWN_ACCOUNT_REQUEST,

  FETCH_PAUSED_DENTISTS,
  FETCH_PAUSED_DENTISTS_SUCCESS,
  ADD_PAUSED_DENTISTS,

  FETCH_PROMOS_REQUEST,
  FETCH_PROMOS_SUCCESS,
  ADD_PROMOS,
  ADD_PROMOS_BY_REGULAR_ADMIN,
  EDIT_PROMOS,

  ADD_FAKE_OFFICES,
  ADD_FAKE_MEMBERS,
  SWITCH_TO_NEW,
} from './constants';


/* Fetch
 * ========================================================================== */

/* Fetch Dentists
 * ------------------------------------------------------ */
export function fetchDentists (managerId,sort,searchTerm,startingAt) {
  return {
    type: FETCH_DENTISTS_REQUEST,
    managerId: managerId === "unassigned" ? null : managerId,
    sort:sort,
    searchTerm,
    startingAt
  };
}

export function fetchDentistsSuccess (payload,sort='newest') {
  return {
    type: FETCH_DENTISTS_SUCCESS,
    payload,
    sort:sort
  };
}

export function refreshDentists(sort="newest"){
  return {
    type: REFRESH_DENTISTS,
    sort:sort
  }
}

export function fetchDentistsSuccessAll (payload,sort,startingAt,noMoreResults) {
    return {
        type: FETCH_DENTISTS_SUCCESS_ALL,
        payload,
        sort:sort,
        startingAt,
        noMoreResults
    };
}

export function fetchDentistsError (payload) {
  return {
    type: FETCH_DENTISTS_ERROR,
    payload,
  };
}

export function fetchDentistList () {
  return {
    type: FETCH_DENTIST_LIST_REQUEST,
  };
}

export function fetchDentistListSuccess (payload) {
  return {
    type: FETCH_DENTIST_LIST_SUCCESS,
    payload,
  };
}

/* Fetch Dentist Details
 * ------------------------------------------------------ */
export function fetchDentistDetails (dentistInfoId) {
  return {
    type: FETCH_DENTIST_DETAILS_REQUEST,
    dentistInfoId,
  };
}

export function updateGeolocationAction (dentistInfoId) {
    return {
        type: UPDATE_GEOLOCATION,
        dentistInfoId,
    };
}

export function importOfficeTransactionsRequest(values) {
  return {
    type:   IMPORT_OFFICE_TRANSACTIONS_REQUEST,
    values: values
  };
}

export function undoStandardAction (dentistInfoId) {
  return {
      type: UNDO_STANDARD,
      dentistInfoId,
  };
}

export function removeBAAAction (dentistInfoId) {
  return {
      type: REMOVE_BAA,
      dentistInfoId,
  };
}


export function updateAffordabilityAction () {
  return {
      type: UPDATE_AFFORDABILITY,
  };
}

export function setOnboardingAction (dentistInfoId,toggle) {
  return {
      type: UPDATE_ONBOARDING,
      dentistInfoId,
      toggle
  };
}

export function setNinetyDayAction (dentistInfoId,toggle) {
  return {
      type: UPDATE_NINETYDAY,
      dentistInfoId,
      toggle
  };
}

export function setMarketingMaterialsPaidAction (dentistInfoId) {
  return {
      type: UPDATE_MARKETING_MATERIALS_PAID,
      dentistInfoId,
  };
}

export function setBoxShippedAction (dentistInfoId) {
  return {
      type: UPDATE_BOX_SHIPPED,
      dentistInfoId,
  };
}

export function setMembershipTrainingAction (dentistInfoId,toggle) {
  return {
      type: UPDATE_MEMBERSHIP_TRAINING,
      dentistInfoId,
      toggle
  };
}

export function toggleMarketingMaterialsPaymentAction(dentistInfoId,toggle) {
  return {
      type: TOGGLE_MARKETING_MATERIALS_CHARGE,
      dentistInfoId,
      toggle
  };
}


export function setBusinessConnectOnAction (dentistInfoId,toggle) {
  return {
      type: UPDATE_BUSINESS_CONNECT_ON,
      dentistInfoId,
      toggle
  };
}

export function setBusinessConnectTrainingOnAction (dentistInfoId,toggle) {
  return {
      type: UPDATE_BUSINESS_CONNECT_TRAINING_ON,
      dentistInfoId,
      toggle
  };
}

export function fetchDentistDetailsSuccess (payload) {
  return {
    type: FETCH_DENTIST_DETAILS_SUCCESS,
    payload,
  };
}

export function fetchDentistDetailsError (payload) {
  return {
    type: FETCH_DENTIST_DETAILS_ERROR,
    payload,
  };
}

/* Fetch Dentist Members
 * ------------------------------------------------------ */
export function fetchDentistMembers (dentistInfoId) {
  return {
    type: FETCH_DENTIST_MEMBERS_REQUEST,
    dentistInfoId,
  };
}

export function fetchSingleMember (memberId) {
  return {
    type: FETCH_SINGLE_MEMBER_REQUEST,
    memberId,
  };
}

export function fetchMembers (managerId,searchTerm) {
    return {
        type: FETCH_MEMBERS_REQUEST,
        managerId: managerId === "unassigned" ? null : managerId,
        searchTerm: searchTerm
    };
}

export function fetchMembersSuccess (payload) {
    return {
        type: FETCH_MEMBERS_SUCCESS,
        payload,
    };
}

export function fetchMembersSuccessAll (payload,dentists) {
    return {
        type: FETCH_MEMBERS_SUCCESS_ALL,
        payload,
        dentists,
    };
}

export function fetchMembersError (payload) {
    return {
        type: FETCH_MEMBERS_ERROR,
        payload,
    };
}


export function fetchDentistMembersSuccess (payload) {
  return {
    type: FETCH_DENTIST_MEMBERS_SUCCESS,
    payload,
  };
}

export function fetchDentistMembersError (payload) {
  return {
    type: FETCH_DENTIST_MEMBERS_ERROR,
    payload,
  };
}

/* Fetch Dentist Reports
 * ------------------------------------------------------ */
export function fetchDentistReports (dentistInfoId) {
  return {
    type: FETCH_DENTIST_REPORTS_REQUEST,
    dentistInfoId,
  };
}

export function fetchDentistReportsSuccess (payload) {
  return {
    type: FETCH_DENTIST_REPORTS_SUCCESS,
    payload,
  };
}

export function fetchDentistReportsError (payload) {
  return {
    type: FETCH_DENTIST_REPORTS_ERROR,
    payload,
  };
}

/* Fetch Dentist Reports
 * ------------------------------------------------------ */
export function fetchManagerReports (managerId) {
  return {
    type: FETCH_MANAGER_REPORTS_REQUEST,
    managerId,
  };
}

export function fetchManagerReportsSuccess (payload) {
  return {
    type: FETCH_MANAGER_REPORTS_SUCCESS,
    payload,
  };
}

export function fetchManagerReportsError (payload) {
  return {
    type: FETCH_MANAGER_REPORTS_ERROR,
    payload,
  };
}

/* Fetch Dentist Reviews
 * ------------------------------------------------------ */
export function fetchDentistReviews (dentistInfoId) {
  return {
    type: FETCH_DENTIST_REVIEWS_REQUEST,
    dentistInfoId,
  };
}

export function fetchDentistReviewsSuccess (payload) {
  return {
    type: FETCH_DENTIST_REVIEWS_SUCCESS,
    payload,
  };
}

export function fetchDentistReviewsError (payload) {
  return {
    type: FETCH_DENTIST_REVIEWS_ERROR,
    payload,
  };
}

/* Fetch Stats
 * ------------------------------------------------------ */
export function fetchStats () {
  return {
    type: FETCH_STATS_REQUEST,
  };
}

export function fetchStatsSuccess (payload) {
  return {
    type: FETCH_STATS_SUCCESS,
    payload,
  };
}

export function fetchStatsError (payload) {
  return {
    type: FETCH_STATS_ERROR,
    payload,
  };
}


/* Setters
 * ========================================================================== */
export function setSelectedDentist (dentist) {
  return {
    type: SET_SELECTED_DENTIST,
    dentist,
  };
}

export function setSelectedManager (manager) {
  return {
    type: SET_SELECTED_MANAGER,
    manager,
  };
}

export function setSelectedMember (member) {
    return {
        type: SET_SELECTED_MEMBER,
        member,
    };
}

export function setEditingDentistInfoId (dentistInfoId) {
  return {
    type: SET_EDITING_DENTIST_ID,
    dentistInfoId,
  };
}

export function adminLogin (dentistInfoId) {
  return {
    type: REDIRECT_TO_ADMIN,
    dentistInfoId,
  };
}


/* Search / Sort
 * ========================================================================== */
export function search (name,pageName) {
  return {
    type: SEARCH,
    name,
    pageName
  };
}

export function sort (sortStatus,filterStatus,pageName) {
  return {
    type: SORT,
    sortStatus,
    filterStatus,
    pageName
  };
}


/* Actions
 * ========================================================================== */

/* Edit Dentist
 * ------------------------------------------------------ */
export function editDentist (selectedDentist, values) {
  return {
    type: EDIT_DENTIST_REQUEST,
    selectedDentist,
    values
  };
}

export function editDentistSuccess (payload) {
  return {
    type: EDIT_DENTIST_SUCCESS,
    payload,
  };
}

export function editDentistError (payload) {
  return {
    type: EDIT_DENTIST_ERROR,
    payload
  };
}

/* Download Report
 * ------------------------------------------------------ */
export function downloadReport (reportName, reportUrl) {
  return {
    type: DOWNLOAD_REPORT_REQUEST,
    reportName,
    reportUrl,
  };
}

export function downloadReportSuccess () {
  return {
    type: DOWNLOAD_REPORT_SUCCESS,
  };
}

export function downloadReportFailure (payload) {
  return {
    type: DOWNLOAD_REPORT_ERROR,
    payload,
  };
}

/* Download Master Report
 * ------------------------------------------------------ */
export function downloadMasterReport (year, month,secondary,top) {
  return {
    type: DOWNLOAD_MASTER_REPORT_REQUEST,
    year,
    month,
    secondary,
    top
  };
}

export function downloadMasterReportSuccess () {
  return {
    type: DOWNLOAD_MASTER_REPORT_SUCCESS,
  };
}

export function downloadMasterReportFailure (payload) {
  return {
    type: DOWNLOAD_MASTER_REPORT_ERROR,
    payload,
  };
}

/* Delete Dentist Review
 * ------------------------------------------------------ */
export function deleteDentistReview (dentistInfoId, reviewId) {
  return {
    type: DELETE_DENTIST_REVIEW_REQUEST,
    dentistInfoId,
    reviewId,
  };
}

export function deleteDentistReviewSuccess (dentistInfoId, reviewId) {
  return {
    type: DELETE_DENTIST_REVIEW_SUCCESS,
    dentistInfoId,
    reviewId,
  };
}

export function deleteDentistReviewError (payload) {
  return {
    type: DELETE_DENTIST_REVIEW_ERROR,
    payload,
  };
}

// Managers
export function fetchManagers () {
  return {
    type: FETCH_MANAGERS_REQUEST,
  };
}

export function fetchSupports () {
  return {
    type: FETCH_SUPPORTS_REQUEST,
  };
}


export function toggleRefundingMember (value) {
  return {
    type: TOGGLE_REFUNDING_MEMBER,
    payload: value
  };
}

export function initiateRefundingMember (id, amount,reason) {
  return {
    type: INITIATE_REFUNDING_MEMBER,
    id,
    amount,
    reason,
  };
}

export function toggleChargingMember (value) {
  return {
    type: TOGGLE_CHARGING_MEMBER,
    payload: value
  };
}

export function initiateChargingMember (id, amount,description,reason,stripeFee,dentalHQFee) {
  return {
    type: INITIATE_CHARGING_MEMBER,
    id,
    amount,
    description,
    reason,
    stripeFee,
    dentalHQFee,
  };
}

export function toggleDrawDateMember (value) {
  return {
    type: TOGGLE_DRAWDATE_MEMBER,
    payload: value
  };
}

export function toggleEnrollNoCharge (value) {
  return {
    type: TOGGLE_ENROLL_NO_CHARGE,
    payload: value
  };
}


export function toggleToCashMember (value) {
  return {
    type: TOGGLE_TO_CASH_MEMBER,
    payload: value
  };
}

export function toggleBackdateMember (value) {
  return {
    type: TOGGLE_BACKDATE_MEMBER,
    payload: value
  };
}

export function initiateDrawDateMember (id, drawDate) {
  return {
    type: INITIATE_DRAWDATE_MEMBER,
    id,
    drawDate,
  };
}
export function initiateToCashMember(id,values ) {
  return {
    type: INITIATE_TO_CASH_MEMBER,
    id,
    values
  };
}

export function initiateEnrollNoCharge(values ) {
  return {
    type: INITIATE_ENROLL_NO_CHARGE,
    values
  };
}

export function initiateBackdateMember (id, drawDate) {
  return {
    type: INITIATE_BACKDATE_MEMBER,
    id,
    drawDate,
  };
}


export function fetchMasterReportsDates () {
  return {
    type: FETCH_MASTER_REPORTS_DATES,
  };
}

export function toggleTransferringMember (memberId) {
  return {
    type: TOGGLE_TRANSFERRING_MEMBER,
    memberId
  };
}

export function transferMember (memberId, shouldChargeReEnrollmentFree,officeId) {
  return {
    type: TRANSFER_MEMBER,
    memberId,
    shouldChargeReEnrollmentFree,
    officeId
  };
}

export function toggleChangePlans (value) {
  return {
    type: TOGGLE_CHANGE_PLANS,
    payload: value
  };
}

export function initiateChangePlans (payload) {
  return {
    type: INITIATE_CHANGE_PLANS,
    payload
  };
}

// Terms and conditions update actions

export function toggleTermsUpdate (flag) {
  return {
    type: TOGGLE_TERMS_UPDATE,
    flag
  };
}

export function termsUpdateRequest () {
  return {
    type: TERMS_UPDATE_REQUEST
  };
}

// security form
export function toggleSecurityForm (flag) {
  return {
    type: TOGGLE_SECURITY_FORM,
    flag
  };
}

export function securityFormSubmitRequest (values) {
  return {
    type: SECURITY_FORM_SUBMIT_REQUEST,
    values
  };
}

export function adminLoginRequest (payload) {
  return {
    type: ADMIN_LOGIN_REQUEST,
    payload,
  };
}

export function adminLoginSpecificUserRequest (payload) {
  return {
    type: ADMIN_LOGIN_SPECIFIC_USER,
    payload,
  };
}

export function adminLoginDirectlyToMember (dentistInfoId,returning_url) {
  return {
    type: ADMIN_LOGIN_DIRECTLY_TO_MEMBER,
    dentistInfoId,
    returning_url
  };
}

export function memberLoginRequest (payload) {
  return {
    type: MEMBER_LOGIN_REQUEST,
    payload,
  };
}

export function moveUserToOwnAccountRequest (memberId,email) {
  return {
    type: MOVE_MEMBER_TO_OWN_ACCOUNT_REQUEST,
    memberId,
    email
  };
}

export function toggleDeletionRequest (memberId,isDeleted) {
  return {
    type: TOGGLE_DELETION_REQUEST,
    memberId,
    isDeleted
  };
}

export function fetchMembershipsSuccess(payload){
  return {
    type: FETCH_MEMBERSHIPS_SUCCESS,
    payload,
  };
}


export function deleteIncompleteOfficeRequest(dentistInfoId){
  return {
    type: DELETE_INCOMPLETE_OFFICE,
    dentistInfoId,
  };
}

export function deleteIncompleteOfficeSuccess(dentistInfoId){
  return {
    type: DELETE_INCOMPLETE_SUCCESS,
    dentistInfoId,
  };
}



export function setCompleteOfficeRequest(dentistInfoId){
  return {
    type: SET_COMPLETE_OFFICE,
    dentistInfoId,
  };
}

export function fetchBoxes () {
  return {
    type: FETCH_BOX_REPORTS,
  };
}

export function fetchPausedDentists () {
  return {
    type: FETCH_PAUSED_DENTISTS,
  };
}

export function fetchPausedDentistsSuccess (payload) {
  return {
    type: FETCH_PAUSED_DENTISTS_SUCCESS,
    payload,
  };
}

export function addPausedDentists(values){
  return {
    type: ADD_PAUSED_DENTISTS,
    values,
  };
}

export function fetchPromoCodes () {
  return {
    type: FETCH_PROMOS_REQUEST,
  };
}

export function fetchPromoSuccess (payload) {
  return {
    type: FETCH_PROMOS_SUCCESS,
    payload
  };
}

export function addPromos(values){
  return {
    type: ADD_PROMOS,
    values,
  };
}

export function addPromosByRegularAdmin(values){
  return {
    type: ADD_PROMOS_BY_REGULAR_ADMIN,
    values,
  };
}

export function editPromos(values){
  return {
    type: EDIT_PROMOS,
    values,
  };
}

export function addFakeOfficesRequest(values){
  return {
    type: ADD_FAKE_OFFICES,
    values,
  };
}

export function addFakeMembersRequest(values){
  return {
    type: ADD_FAKE_MEMBERS,
    values,
  };
}


export function switchToNewRequest(values){
  return {
    type: SWITCH_TO_NEW,
    values,
  };
}


