/*
NavBar Component
================================================================================
NOTE: Add custom left-hand-side elements for routes in the `returnLinks`
      dictionary (a class field).
*/

/*
Imports
------------------------------------------------------------
*/
// libs
import React from 'react';
import PropTypes from 'prop-types';
// import Image from 'react-bootstrap/lib/Image';
import Navbar from 'react-bootstrap/lib/Navbar';
import Nav from 'react-bootstrap/lib/Nav';
import MenuItem from 'react-bootstrap/lib/MenuItem';
import NavDropdown from 'react-bootstrap/lib/NavDropdown';
import CSSModules from 'react-css-modules';
import { connect } from 'react-redux';
import { Link } from 'react-router';
import { push } from 'react-router-redux';
import SideNavDashboard from 'components/SideNavDashboard';
import FaCog from 'react-icons/lib/fa/cog';
import FaQuestion from 'react-icons/lib/fa/question';
import FaMapMarker from 'react-icons/lib/fa/map-marker';
import FaClose from 'react-icons/lib/fa/close';
import FaSearch from 'react-icons/lib/fa/search';
import FormControl from 'react-bootstrap/lib/FormControl';
import FormGroup from 'react-bootstrap/lib/FormGroup';
import InputGroup from 'react-bootstrap/lib/InputGroup';
import { setItem, removeItem } from 'utils/localStorage';
import { toast } from 'react-toastify';

// app
import dentalhqLogo from 'new/assets/DHQ-logo_RGB_fullcolor-ondark.png';
import { selectCurrentUser } from 'containers/App/selectors';

import Cookies from 'universal-cookie';

const cookies = new Cookies();

// local
import styles from './styles.module.css';

/*
Redux
------------------------------------------------------------
*/
function mapStateToProps (state) {
  return {
    loggedInUser: selectCurrentUser(state),
    user: selectCurrentUser(state),
  };
}


function mapDispatchToProps (dispatch) {
  return {
    changeRoute: (url) => dispatch(push(url)),
    
  }
}

/*
NavBar
================================================================================
*/
@connect(mapStateToProps, mapDispatchToProps)
@CSSModules(styles, { allowMultiple: true })
export default class NavBar extends React.Component {

  static propTypes = {
    changeRoute: PropTypes.func,
    loggedInUser: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ]),
    pathname: PropTypes.string,
    user: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ]),

  };

  constructor(props) {
    super(props);
  }


  selectTab = (key) => {
    switch (key) {
      case 'edit-profile':
        this.props.changeRoute('/associations/edit-profile');
        break;
      case 'custom':
        this.props.changeRoute('/associations/custom-membership');
        break;

      case 'users':
        this.props.changeRoute('/associations/users');

        break;
      case 'profile':
        this.props.changeRoute('/associations/profile');
        break;
      default:
        // Don't do anything... we should never actually reach here.
        break;
    }
  }

  onBackToAdmin =   () => {
    localStorage.setItem('auth_token', cookies.get('beccascookie'));
    cookies.remove('associationId',{ path: '/' });
    cookies.remove('beccascookie',{ path: '/' });
    setTimeout(() => window.location='/admin/dentists', 250);
}

copyEmail=(e) =>{
  
  var inputtext=document.querySelector("#emailSelector");
  inputtext.select();
  document.execCommand('copy');
  toast.success("Copied to clipboard: " + document.getElementById("emailSelector").value);
}

  render () {
    const { logo,
      dentistLink,
      user,
      pathname,
      currentSearchTerm,
      currentFilterTerm,
     } = this.props;

    const { firstName,
       lastName,
        avatar,
      } = this.props.loggedInUser;

      return (
        <Navbar fixedTop styleName="navbar-admin">
            <Nav className="col-sm-2" styleName="navbar__col">
            <img src={dentalhqLogo} alt="DentalHQ" styleName="navbar__brand__img" />
            </Nav>

            <Nav className="col-sm-7" pullRight>
              <div styleName="location-selector">
                <div styleName="location-dropdown">
                <div styleName="location-welcome">{user.firstName!=undefined?(<span>{user.firstName} <span styleName="url-separator">|</span><input onClick={this.copyEmail} type="text" value={user.email} id="emailSelector" styleName="url" style={{width:((user.email.length + 1) * 6) + 'px'}}/></span>):"Welcome"}</div>
                   <NavDropdown styleName="navbar__brand__link" title={(user.associationUsers!=undefined?user.associationUsers[0].associations.name:'Association')+' Dashboard'} styleName="topdropdown" id="topdropdown">
                      <MenuItem styleName={"header-link top-link" + (this.props.pathname.indexOf('admin/profile') > -1 ? " header-link--current" : "")} onClick={() =>this.selectTab('profile')}>
                        <FaCog/>Settings
                      </MenuItem>
                      <MenuItem styleName="header-link top-link" onClick={cookies.set('userId',this.props.user.id,{path:'/'})} href="/accounts/login">
                        <FaClose/>Log Out
                      </MenuItem>
                  </NavDropdown>
                  {cookies.get('beccascookie')&&(
                    <NavDropdown styleName="navbar__brand__link topdropdownsecond" title='Logged In as Admin' id="adminnavdropdown">
                      <MenuItem styleName="header-link top-link" onClick={() =>this.onBackToAdmin()}>
                        <FaClose/>Back to Admin
                      </MenuItem>


                  </NavDropdown>
                  )}
                </div>
              
              </div>
            </Nav>

        </Navbar>
      );
  }
}
