import { TARGET_MAIN_OPTION } from 'new/constants/general';
import useActiveOffice from 'new/hooks/useActiveOffice';
import { useLocationSelector } from 'new/hooks/useLocationSelector';
import { AssignPaymentMethodLocationsRequest } from 'new/modules/BillingPage/types';
import {
  assignPlans,
  changeMasterPlanSortOrder,
  changeMembershipSettingsML,
  changeTargetEndpoint,
  editPlan,
  getDentistWorryFreeEndpoint,
  getGlobalSettingsUcrs,
  getGroupPlansList,
  getLegacyPlansList,
  getMasterPlanDetail,
  getMultiPlanSettings,
  getPlanDetail,
  getPlansList,
  getSimplePlansList,
  mergeLegacyPlans,
  removeAssignPlans,
  savePlanSpanish,
  setGuaranteedPlanPaymentsEndpoint,
  setPlanSettings,
  toggleGuaranteedPlanPaymentsEndpoint,
  updatePlanPricing,
  updatePlanStatus,
} from 'new/modules/ManagePlans/endpoints';
import {
  AssignPlansRequest,
  ChangeTargetRequest,
  ChangeTargetResponse,
  GetMultiPlanSettingsRequest,
  GetPlanSettingsRequest,
  GuaranteedPlanPaymentsRequest,
  GuaranteedPlanPaymentsToggleRequest,
  LegacyPlan,
  ManagePlan,
  ManagePlanDetail,
  ManagePlansSettings,
  ManagePlansTabsStateStore,
  ManageTabs,
  MergeLegacyPlansRequest,
  RemoveAssignPlansRequest,
  SavePlanSpanishRequest,
  SelectedLegacyPlans,
  TargetType,
  UpdatePlanPricingMultiRequest,
  UpdatePlanPricingMultiResponse,
  UpdatePlanStatusMultiRequest,
  WorryFreeResponse,
} from 'new/modules/ManagePlans/types';
import { assignPaymentMethodToLocations } from 'new/modules/MultiLocationBilling/endpoints';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import create, { GetState, SetState } from 'zustand';
import { StoreApiWithPersist, persist } from 'zustand/middleware';
import { useGetPlans } from '../MembershipPlansManage/hooks';
import {
  Code,
  EditPlanRequest,
  EditPlanResponse,
  MembershipSettingsRequest,
  PlanStorePlan,
} from '../MembershipPlansManage/types';
import { NEW_TAB_ID } from './utils';
import { Id } from 'new/api/types';

export const useManagePlansTabsStateStore = create<
  ManagePlansTabsStateStore,
  SetState<ManagePlansTabsStateStore>,
  GetState<ManagePlansTabsStateStore>,
  StoreApiWithPersist<ManagePlansTabsStateStore>
>(
  persist(
    (set) => ({
      managePlansTabsState: [] as ManageTabs,
      setManagePlansTabsState: (managePlansTabsState) =>
        set({ managePlansTabsState }),
      resetManagePlansTabsState: () => set({ managePlansTabsState: [] }),
    }),
    {
      name: 'managePlansTabsState',
      version: 1,
      getStorage: () => sessionStorage,
    },
  ),
);

// export const useChangeMasterPlansOrder = () =>
//   useMutation<unknown, unknown, ChangePlansOrderRequest>(
//     ({ dentistInfoId, ...params }) =>
//     changeMasterPlanSortOrder.endpoint(params, dentistInfoId).then((res) => res.data),
//   );

export const useChangeMasterPlansOrder = () =>
  useMutation<unknown, unknown, unknown>((params) =>
    changeMasterPlanSortOrder.endpoint(params).then((res) => res.data),
  );

export const useGetPlansList = (organizationId?: number) =>
  useQuery({
    queryKey: ['MANAGE_PLANS_LIST', organizationId],
    queryFn: () =>
      getPlansList.endpoint({}, organizationId).then((res) => res.data),
    enabled: !!organizationId,
  });

export const useGetGroupPlansList = (dentistInfoId?: number) =>
  useQuery({
    queryKey: ['MANAGE_GROUP_PLANS_LIST', dentistInfoId],
    queryFn: () =>
      getGroupPlansList.endpoint({}, dentistInfoId).then((res) => res.data),
    enabled: !!dentistInfoId,
  });

export const useGetSimplePlansList = (dentistInfoId?: number) => {
  return useQuery({
    queryKey: ['MANAGE_SIMPLE_PLANS_LIST', dentistInfoId],
    queryFn: (): Promise<ManagePlan[]> =>
      getSimplePlansList.endpoint({}, dentistInfoId).then((res) =>
        res.data.custom_memberships.map((plan) => ({
          id: plan.id,
          planName: plan.name,
          description: plan.description,
          notes: plan.notes,
          pricing: {
            monthly: Number(plan.price),
            annually: Number(plan.yearly_membership[0].price),
          },
          memberCount: {
            monthly: Number(plan.member_count_monthly),
            annual: Number(plan.member_count_yearly),
          },
          locations: [],
          groups: [],
          viewStatus: plan.viewStatus,
          hasActiveMembers: false,
          target: plan.target,
          targetType: plan.targetType,
        })),
      ),
    enabled: !!dentistInfoId,
  });
};

export const useGetAllPlans = (
  isGroup: boolean,
  organizationId?: number,
  dentistInfoId?: number,
) => {
  const {
    data = [],
    isLoading,
    refetch: refetchMaster,
  } = useGetPlansList(organizationId);
  const {
    data: simpleData = [],
    isLoading: isLoadingSimple,
    refetch: refetchSimple,
  } = useGetSimplePlansList(!isGroup ? dentistInfoId : undefined);
  const {
    data: groupData = [],
    isLoading: isLoadingGroup,
    refetch: refetchGroup,
  } = useGetGroupPlansList(isGroup ? dentistInfoId : undefined);

  const refetch = () => {
    if (organizationId) refetchMaster();
    if (!isGroup && dentistInfoId) refetchSimple();
    if (isGroup && dentistInfoId) refetchGroup();
  };

  return {
    masterPlans: data,
    simplePlans: simpleData,
    groupPlans: groupData,
    isLoading: isLoading || isLoadingSimple || isLoadingGroup,
    refetch,
  };
};

// export const useGetGlobalPlanSettings = (userId?: number) =>
//   useQuery({
//     queryKey: ['MANAGE_PLANS_SETTINGS', userId],
//     queryFn: () => getGlobalSettingsUcrs.endpoint({}, userId).then((res) => res.data),
//     enabled: !!userId,
//   });
export const useChangeMembershipSettingsML = () =>
  useMutation<unknown, unknown, MembershipSettingsRequest>((params) =>
    changeMembershipSettingsML.endpoint(params).then((res) => res.data),
  );

export const useGetGlobalPlanSettings = (
  organizationId?: number,
  getGlobalPlanSettingsRequest?: GetPlanSettingsRequest,
) =>
  useQuery({
    queryKey: ['MANAGE_PLANS_SETTINGS', getGlobalPlanSettingsRequest],
    queryFn: () =>
      getGlobalSettingsUcrs()
        .endpoint(getGlobalPlanSettingsRequest, '')
        .then((res) => res.data),
    enabled: !!organizationId,
  });

export const useGetMultiPlanSettingsML = (
  params: GetMultiPlanSettingsRequest,
) =>
  useQuery({
    queryKey: [
      'GET_MULTI_PLANS_SETTINGS' +
        params.target +
        params.organizationId +
        params.isGroup,
    ],
    queryFn: () =>
      getMultiPlanSettings()
        .endpoint(params)
        .then((res) => res.data),
    enabled: !!params.organizationId,
  });

export const useGetPlanDetail = (planId?: number) =>
  useQuery<ManagePlanDetail>({
    queryKey: ['MANAGE_SIMPLE_PLAN_DETAIL', planId],
    queryFn: () =>
      getPlanDetail.endpoint({}, planId).then((res) => ({
        ...res.data,
        showOnEnrollmentAnnual: res.data.showOnEnrollmentAnnual,
        showOnEnrollmentMonthly: res.data.showOnEnrollmentMonthly,
        showOnInternalEnrollmentMonthly:
          res.data.showOnInternalEnrollmentMonthly,
        showOnInternalEnrollmentAnnual: res.data.showOnInternalEnrollmentAnnual,
      })),
    enabled: !!planId,
  });

export const useGetMasterPlanDetail = (planId?: number) =>
  useQuery<ManagePlanDetail>({
    queryKey: ['MANAGE_PLAN_DETAIL', planId],
    queryFn: () =>
      getMasterPlanDetail.endpoint({}, planId).then((res) => res.data),
    enabled: !!planId,
  });

export const useAssignPlans = (userId?: number) =>
  useMutation<unknown, unknown, AssignPlansRequest & { userId?: number }>(
    (params) =>
      assignPlans
        .endpoint(params, userId || params.userId)
        .then((res) => res.data),
  );

export const useUpdatePlanStatus = () =>
  useMutation<unknown, unknown, UpdatePlanStatusMultiRequest>((params) =>
    updatePlanStatus.endpoint(params).then((res) => res.data),
  );

export const useUpdatePlanPricing = () =>
  useMutation<
    UpdatePlanPricingMultiResponse,
    unknown,
    UpdatePlanPricingMultiRequest
  >((params) => updatePlanPricing.endpoint(params).then((res) => res.data));

export const useRemoveAssignPlans = (userId?: number) =>
  useMutation<unknown, unknown, RemoveAssignPlansRequest>((params) =>
    removeAssignPlans.endpoint(params, userId).then((res) => res.data),
  );

export const useAssignPaymentMethodToLocations = () =>
  useMutation<unknown, unknown, AssignPaymentMethodLocationsRequest>((params) =>
    assignPaymentMethodToLocations.endpoint(params),
  );

export const useSetGlobalPlanSettings = () =>
  useMutation(setPlanSettings.endpoint);

export const useGetLegacyPlans = (organizationId: number) =>
  useQuery({
    queryKey: ['LEGACY_PLANS_LIST', organizationId],
    queryFn: () =>
      getLegacyPlansList.endpoint({ organizationId }).then((res) =>
        res.data.memberships
          .map(
            (membership) =>
              ({
                id: membership.id,
                name: membership.name,
                description: membership.description,
                locationId: membership.ownerInfo.id,
                location: membership.ownerInfo.officeName,
                internalName: membership.ownerInfo.daoi.internalName,
                price: {
                  month: membership.active
                    ? parseFloat(membership.price)
                    : undefined,
                  year: membership.yearly_membership[0].active
                    ? parseFloat(membership.yearly_membership[0].price)
                    : undefined,
                },
                status: membership.viewStatus,
                members_monthly: membership.member_count_monthly,
                members_yearly: membership.member_count_yearly,
              } as LegacyPlan),
          )
          .sort((a, b) => {
            const monthDiff = (a.price?.month || 0) - (b.price?.month || 0);
            const yearDiff = (a.price?.month || 0) - (b.price?.month || 0);
            return monthDiff !== 0 ? monthDiff : yearDiff;
          }),
      ),
    enabled: !!organizationId,
  });

export const useSelectedLegacyPlans = create<SelectedLegacyPlans>((set) => ({
  selected: [],
  set: (rows) => set({ selected: rows }),
}));

export const useMergeLegacyPlans = () =>
  useMutation<unknown, unknown, MergeLegacyPlansRequest>((params) =>
    mergeLegacyPlans.endpoint(params).then((res) => res.data),
  );

export const useUpdatePlan = () =>
  useMutation<
    EditPlanResponse,
    unknown,
    EditPlanRequest & { dentistOfficeId: number }
  >(({ dentistOfficeId, ...params }) =>
    editPlan.endpoint(params, dentistOfficeId).then((res) => res.data),
  );

export const useSetGuaranteedPlanPayments = () =>
  useMutation<unknown, unknown, GuaranteedPlanPaymentsRequest>((params) =>
    setGuaranteedPlanPaymentsEndpoint.endpoint(params).then((res) => res.data),
  );

export const useToggleGuaranteedPlanPayments = () =>
  useMutation<unknown, unknown, GuaranteedPlanPaymentsToggleRequest>((params) =>
    toggleGuaranteedPlanPaymentsEndpoint
      .endpoint(params)
      .then((res) => res.data),
  );

export const useChangeTarget = () =>
  useMutation<ChangeTargetResponse, unknown, ChangeTargetRequest>((params) =>
    changeTargetEndpoint.endpoint(params).then((res) => res.data),
  );

export const useGetWorryFree = (dentistInfoId: number) =>
  useQuery<WorryFreeResponse>({
    queryKey: ['DENTIST_WORRY_FREE', dentistInfoId],
    queryFn: () =>
      getDentistWorryFreeEndpoint
        .endpoint({}, dentistInfoId)
        .then((res) => res.data),
  });

export const useUnassociatedTabWarning = ({
  handleCloseTab,
  planId,
  manageTabState,
}: {
  handleCloseTab: (planId: number) => void;
  planId: number;
  manageTabState: ManageTabs;
}) => {
  const { selectedLocation, setSelectedLocation, targetType } =
    useLocationSelector();
  const [isUnassociatedTargetModalOpen, setIsUnassociatedTargetModalOpen] =
    useState<boolean>(false);

  const getTarget = useCallback(() => {
    const associatedTab = manageTabState.filter((state) => state.id === planId);
    return {
      target:
        associatedTab.length > 0
          ? associatedTab[0].associatedTarget
          : TARGET_MAIN_OPTION.value,
      targetType:
        associatedTab.length > 0 ? associatedTab[0].targetType : 'master',
    };
  }, [planId, manageTabState]);

  const closeTabAndChangeTarget = () => {
    setIsUnassociatedTargetModalOpen(false);
    handleCloseTab(planId);
  };

  const abortChangeTarget = () => {
    setSelectedLocation(getTarget().target);
    setIsUnassociatedTargetModalOpen(false);
  };

  useEffect(() => {
    const { target: associatedTarget, targetType: associatedTargetType } =
      getTarget();
    setIsUnassociatedTargetModalOpen(
      associatedTarget !== selectedLocation ||
        associatedTargetType !== targetType,
    );
  }, [selectedLocation, targetType, getTarget]);
  return {
    isUnassociatedTargetModalOpen,
    setIsUnassociatedTargetModalOpen,
    closeTabAndChangeTarget,
    abortChangeTarget,
  };
};

export const useGetPlan = (targetType: TargetType, planId?: number) => {
  const {
    data: masterData,
    isLoading: masterIsLoading,
    refetch: masterRefetch,
  } = useGetMasterPlanDetail(
    targetType && ['master', 'group'].includes(targetType) ? planId : undefined,
  );
  const {
    data: simpleData,
    isLoading: simpleIsLoading,
    refetch: simpleRefetch,
  } = useGetPlanDetail(targetType === 'location' ? planId : undefined);
  const { data, isLoading, refetch } = ['master', 'group'].includes(targetType)
    ? {
        data: masterData,
        isLoading: masterIsLoading,
        refetch: masterRefetch,
      }
    : {
        data: simpleData,
        isLoading: simpleIsLoading,
        refetch: simpleRefetch,
      };

  return { data, isLoading, refetch };
};

export const useNextDuplicateId = (
  manageTabState: ManageTabs | PlanStorePlan[],
) => {
  const duplicateId = useMemo(() => {
    const highestTabId = Math.max(
      ...manageTabState.map((plan) => Number(plan.id)),
    );
    return Math.max(NEW_TAB_ID, highestTabId) + 1;
  }, [manageTabState]);

  return duplicateId;
};

export const usePlanSettings = () => {
  const { orgId } = useActiveOffice();
  const { selectedLocation, targetType } = useLocationSelector();
  const {
    data: planSettings,
    isLoading,
    isRefetching,
    refetch: refetchMLSettings,
  } = useGetMultiPlanSettingsML({
    isGroup: targetType === 'group',
    organizationId: targetType !== 'location' ? orgId : undefined,
    target: selectedLocation == 'all' ? orgId?.toString() : selectedLocation,
  });
  const {
    data: plansData,
    isLoading: isLoadingPlans,
    refetch: refetchSLSettings,
    isRefetching: isRefetchingPlans,
  } = useGetPlans(
    targetType === 'location' ? Number(selectedLocation) : undefined,
  );

  const refetch = () => {
    if (targetType === 'location') {
      refetchSLSettings();
    } else {
      refetchMLSettings();
    }
  };

  if (targetType === 'location') {
    const planSettings: ManagePlansSettings = {
      programName: plansData?.programName ?? '',
      programDescription: plansData?.programDescription ?? '',
      dentistSpecialtyId: plansData?.dentistSpecialtyId ?? 0,
      priceCodes: (plansData?.priceCodes ?? []) as Code[],
      familyDiscounts: {
        family_discount_four:
          plansData?.familyDiscounts.family_discount_four ?? null,
        family_discount_three:
          plansData?.familyDiscounts.family_discount_three ?? null,
        family_discount_two:
          plansData?.familyDiscounts.family_discount_two ?? null,
      },
      worryFree: {
        adult: { monthly: 0, annual: 0 },
        child: { monthly: 0, annual: 0 },
        perio: { monthly: 0, annual: 0 },
      },
      allowBankDraft: plansData?.allowBankDraft ?? false,
      gppActive: plansData?.gppActive ?? false,
      gppAdminOnlyWaive: plansData?.gppAdminOnlyWaive ?? false,
      gppApproved: plansData?.gppApproved ?? false,
      gppMemberWaive: plansData?.gppMemberWaive ?? false,
      gppAutomaticClaim: plansData?.gppAutomaticClaim ?? false,
      disableEnrollment: plansData?.disableEnrollment ?? false,
    };
    return {
      planSettings,
      isLoading: isLoadingPlans || isRefetchingPlans,
      refetch,
    };
  }
  return {
    planSettings,
    isLoading: isLoading || isRefetching,
    refetch,
  };
};

export const useSavePlanSpanish = (id: Id) =>
  useMutation<unknown, unknown, SavePlanSpanishRequest>((params) =>
    savePlanSpanish.endpoint(params, id).then((res) => res.data),
  );
